import NiceModal, { useModal } from '@ebay/nice-modal-react'
import copy from 'copy-to-clipboard'
import { useContext } from 'react'
import { toast } from 'react-hot-toast'

import Button from 'components/form/Button'
import Modal from 'components/modal'
import { AuthContext } from 'context/AuthContext'

export default NiceModal.create(() => {
  const { user } = useContext(AuthContext)

  const modal = useModal()
  const refLink = `${process.env.REACT_APP_USER_URL}/register?referralId=` + user?.referralId || ''

  return (
    <Modal
      title='Invite a Friend'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-3xl'
    >
      <div className='flex flex-wrap border bg-[#222] border-slate-800 rounded-md p-3 gap-5 xl:gap-10 justify-between items-center'>
        <code className='break-all text-slate-200 sm:flex-1'>{refLink}</code>
        <Button
          onClick={() => {
            copy(refLink)
            toast.success('Copied to clipboard')
          }}
          className='!px-5 whitespace-nowrap rounded-md'
          size='lg'
          color='primary'
        >
          Copy Link
        </Button>
      </div>
    </Modal>
  )
})
