export const StatusColorFinder = (type: string): StatusColor => {
  if (['credit', 'confirmed', 'FREE', 'claimed', 'confirmed'].includes(type)) {
    return 'success'
  } else if (['debit', 'PREMIUM'].includes(type)) {
    return 'danger'
  } else if (['pending', 'EXCLUSIVE', 'pending'].includes(type)) {
    return 'warning'
  }

  return 'info'
}
