import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { tickFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

// const data = [
//   {
//     name: 'Mon',
//     tw: 4000,
//     lw: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Tue',
//     tw: 3000,
//     lw: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Wed',
//     tw: 2000,
//     lw: 8,
//     amt: 2290,
//   },
//   {
//     name: 'Thu',
//     tw: 2780,
//     lw: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Fri',
//     tw: 18,
//     lw: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Sat',
//     tw: 2390,
//     lw: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Sun',
//     tw: 3490,
//     lw: 4300,
//     amt: 2100,
//   },
// ]

export default function DividendYield() {
  const { data } = useQuery<WeeklyDividendYield, Error>('weeklyDividendYield', async () => {
    try {
      const res = await privateRequest.get('user/dashboard/weeklyDividendYield')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const dataList = data
    ? Object.entries(data).map(([key, value]: any) => {
        return {
          name: key,
          tw: value.thisWeek,
          lw: value.lastWeek,
        }
      })
    : []

  return (
    <div className='card pb-5'>
      <CardTitle title='Dividend Yield' />
      <div className='h-[400px]'>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            width={500}
            height={300}
            data={dataList}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#D2EAFF' vertical={false} strokeWidth={0.5} opacity={0.5} />
            <XAxis
              dataKey='name'
              tickMargin={10}
              tickLine={false}
              axisLine={false}
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            />
            <YAxis
              axisLine={false}
              tickMargin={10}
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              tickCount={8}
              tickFormatter={tickFormatter}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1E2A47',
                border: 'none',
                borderRadius: '10px',
                color: '#fff',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14px',
                padding: '10px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
              formatter={(value: number) => Number(value?.toFixed(2) || 0)}
            />
            <Legend
              iconType='circle'
              iconSize={8}
              wrapperStyle={{
                color: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
            />
            <Bar dataKey='tw' barSize={4} name='This Week' fill='#00FF00' />
            <Bar
              dataKey='lw'
              barSize={4}
              name='Last Week'
              fill='#FFFF00
'
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
