import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'

import bearishImg from 'images/bearish.svg'
import bullishImg from 'images/bullish.svg'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  title: string
  bearish?: number
  bullish?: number
  type: 'tstock' | 'crypto' | 'forex' | 'hardAsset'
}

export default function Sentiment({ title, bearish = 0, bullish = 0, type }: Props) {
  const queryClient = useQueryClient()
  const submitPoll = useMutation<{ message: string }, Error, { id: string; type: string }>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/poll/submit', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        if (type == 'crypto') {
          queryClient.invalidateQueries('crypto-statistics')
        }
        if (type == 'forex') {
          queryClient.invalidateQueries('forex-statistics')
        }
        if (type == 'hardAsset') {
          queryClient.invalidateQueries('hardAsset-statistics')
        }
        if (type == 'tstock') {
          queryClient.invalidateQueries('tstock-statistics')
        }
      },
    },
  )

  const { data } = useQuery<Poll[], Error>('polls', async () => {
    try {
      const res = await privateRequest.get('user/poll/list')
      return res.data.data
    } catch (err) {
      errorHandler(err)
    }
  })

  const currentPoll = data?.filter((el) => el.type === type)

  const submitPollHandler = (vote: 'bullish' | 'bearish') => {
    if (!currentPoll) return

    toast.promise(submitPoll.mutateAsync({ id: currentPoll[0]._id, type: vote }), {
      loading: 'Loading...',
      success: (r) => r.message,
      error: (r) => r.message ?? 'Something went wrong!',
    })
  }

  const total = bearish + bullish
  const up = bullish ? Math.round((bullish / total) * 100) : 0
  const down = bearish ? Math.round((bearish / total) * 100) : 0
  return (
    <div className='card'>
      <CardTitle title={title} />

      <div className='my-12 flex items-center justify-around gap-3 text-xs font-medium text-secondary'>
        <div onClick={() => submitPollHandler('bullish')} className='cursor-pointer'>
          <img src={bullishImg} alt='' />
          <p className='text-white mt-[10px] text-center'>Bullish</p>
        </div>
        OR
        <div onClick={() => submitPollHandler('bearish')} className='cursor-pointer'>
          <img src={bearishImg} alt='' />
          <p className='text-white mt-[10px] text-center'>Bearish</p>
        </div>
      </div>
      <div className='text-xs font-medium text-secondary'>
        <div className='bg-[#FF4555] h-2 rounded-full mb-2'>
          <div className='bg-[#6CE79A] h-full rounded-full' style={{ width: `${up}%` }}></div>
        </div>
        <div className='flex'>
          <span className='-translate-x-2' style={{ marginLeft: `${up / 2}%` }}>
            {up}%
          </span>
          <span className='ml-auto -translate-x-2' style={{ marginRight: `${down / 2}%` }}>
            {down}%
          </span>
        </div>
      </div>
    </div>
  )
}
