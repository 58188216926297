import { privateRequest } from 'config/axios.config'
import moment from 'moment'
import { useQuery } from 'react-query'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { tickFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function HardAssetsLineChart() {
  const { data } = useQuery<
    {
      _id: string
      totalYield: number
    }[],
    Error
  >('hardAsset-chartData', async () => {
    try {
      const res = await privateRequest.get('user/hardAsset/chartData')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card pb-5 mt-4'>
      <div className='h-[320px]'>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            width={500}
            height={300}
            data={data?.map((el) => ({ name: el._id, Amount: el.totalYield })).reverse() ?? []}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#D2EAFF' vertical={false} strokeWidth={0.5} opacity={0.5} />
            <XAxis
              dataKey='name'
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => moment(value).format('YYYY-MM-DD')}
            />
            <YAxis
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              orientation='left'
              tickCount={8}
              tickFormatter={tickFormatter}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1E2A47',
                border: 'none',
                borderRadius: '10px',
                color: '#fff',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14px',
                padding: '10px',
                textAlign: 'center',
              }}
              formatter={(value: number) => Number(value?.toFixed(2) || 0)}
              labelFormatter={(value) => moment(value).format('MMM DD, YYYY')}
            
            />
            <Line type='monotone' dataKey='Amount' stroke='#82ca9d'  dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
