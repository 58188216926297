type Props = React.SVGProps<SVGSVGElement>
export default function InvestIcon({ width = 20, height = 18, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.6091 5.56004C19.4746 3.3579 17.9617 1.38745 15.7147 0.775583C12.8937 0.00739359 9.98412 1.67149 9.21593 4.49248C8.88272 5.71605 9.00735 6.95619 9.48528 8.03117H9.48524L8.53872 9.97537H10.9631C11.5402 10.4474 12.2132 10.7954 12.9328 10.9913C15.1836 11.6043 17.4907 10.6687 18.7216 8.83262M16.4159 3.47308L12.2862 8.38248M4.44539 1.59712L1.44513 5.09985H3.09566V10.0239H5.79507V5.09985H7.44565L4.44539 1.59712Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7644 5.34746C13.3244 5.34746 13.7784 4.89767 13.7784 4.34283C13.7784 3.78799 13.3244 3.3382 12.7644 3.3382C12.2044 3.3382 11.7505 3.78799 11.7505 4.34283C11.7505 4.89767 12.2044 5.34746 12.7644 5.34746Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8635 8.53505C16.4235 8.53505 16.8774 8.08526 16.8774 7.53042C16.8774 6.97558 16.4235 6.52579 15.8635 6.52579C15.3035 6.52579 14.8495 6.97558 14.8495 7.53042C14.8495 8.08526 15.3035 8.53505 15.8635 8.53505Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.81223 17.4117H0.97844C0.653906 17.4117 0.390808 17.1486 0.390808 16.8241V16.1675C0.390808 15.843 0.653906 15.5799 0.97844 15.5799H4.81227C5.13681 15.5799 5.3999 15.843 5.3999 16.1675V16.8241C5.39986 17.1486 5.13677 17.4117 4.81223 17.4117Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.79629 17.4117H5.98747C5.66294 17.4117 5.39984 17.1486 5.39984 16.8241V16.1675C5.39984 15.843 5.66294 15.5799 5.98747 15.5799H9.79629C10.1208 15.5799 10.3839 15.843 10.3839 16.1675V16.8241C10.3839 17.1486 10.1208 17.4117 9.79629 17.4117Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7803 17.4117H10.9715C10.647 17.4117 10.3839 17.1486 10.3839 16.8241V16.1676C10.3839 15.843 10.647 15.5799 10.9715 15.5799H14.7803C15.1049 15.5799 15.368 15.843 15.368 16.1676V16.8241C15.3679 17.1487 15.1049 17.4117 14.7803 17.4117ZM4.81223 15.58H0.97844C0.653906 15.58 0.390808 15.3169 0.390808 14.9923V14.3358C0.390808 14.0113 0.653906 13.7482 0.97844 13.7482H4.81227C5.13681 13.7482 5.3999 14.0113 5.3999 14.3358V14.9923C5.39987 15.3169 5.13677 15.58 4.81223 15.58Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.79629 15.58H5.98747C5.66294 15.58 5.39984 15.3169 5.39984 14.9923V14.3358C5.39984 14.0113 5.66294 13.7482 5.98747 13.7482H9.79629C10.1208 13.7482 10.3839 14.0113 10.3839 14.3358V14.9923C10.3839 15.3169 10.1208 15.58 9.79629 15.58Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.79629 13.7482H5.98747C5.66294 13.7482 5.39984 13.4851 5.39984 13.1605V12.504C5.39984 12.1795 5.66294 11.9164 5.98747 11.9164H9.79629C10.1208 11.9164 10.3839 12.1795 10.3839 12.504V13.1605C10.3839 13.4851 10.1208 13.7482 9.79629 13.7482Z'
        stroke='currentColor'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
