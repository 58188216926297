import Devider from 'components/Devider'
import Sentiment from 'components/SentimentAAPL'
import ForexMarketWidget from 'components/tradingViewWidgets/ForexMarketWidget'
import AnalyticsSection from 'features/forex/AnalyticsSection'
import { useForexStatistics } from 'hook/useForexStatistics'

export default function ForexPage() {
  const { data } = useForexStatistics()
  const bearish = data?.poll?.result?.bearish ?? 0
  const bullish = data?.poll?.result?.bullish ?? 0
  return (
    <>
      <AnalyticsSection />
      <Devider />
      <div className='xl:grid grid-cols-11 gap-3'>
        <div className='col-span-8 mb-3 xl:mb-0'>
          <ForexMarketWidget className='h-[400px]' />
        </div>
        <div className='col-span-3'>
          <Sentiment
            type='forex'
            title='What is your sentiment on USD?'
            bearish={bearish}
            bullish={bullish}
          />
        </div>
      </div>
    </>
  )
}
