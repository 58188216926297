import clsx from 'clsx'
import React from 'react'

type Props = {
  title: string | JSX.Element
  children?: React.ReactNode
  className?: string
}

export default function CardTitle({ title, className = '', children }: Props) {
  return (
    <h2 className={clsx('flex items-center gap-2 font-medium text-sm mb-3', className)}>
      <span className='bg-gradient-to-b from-primary to-light h-5 w-[10px] rounded-full' />
      {title}
      {children}
    </h2>
  )
}
