import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import GradientText from 'components/GradientText'
import LoginForm from 'features/login/LoginForm'
import AuthLayout from 'layout/AuthLayout'

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Login</title>
      </Helmet>
      <AuthLayout
        title='Log In Your Account'
        description='Please provide correct username & password'
      >
        <LoginForm />
        <p className='mt-12 text-white text-xs text-center'>
          Haven’t any account?
          <Link className='text-secondary' to='/register'>
            <GradientText className='font-semibold ml-2' direction='bg-gradient-to-b'>
              Create Account
            </GradientText>
          </Link>
        </p>
      </AuthLayout>
    </>
  )
}
