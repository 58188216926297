import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/Button'
import Modal from '.'

import successImg from 'images/success.svg'

type Props = {
  title: string
}

export default NiceModal.create(({ title }: Props) => {
  const modal = useModal()

  return (
    <Modal visible={modal.visible} onCancel={() => modal.remove()} className='max-w-md'>
      <img src={successImg} alt='' />
      <h2 className='my-6 font-semibold text-lg'>{title}</h2>
      <Button
        onClick={() => {
          modal.resolve()
          modal.hide()
        }}
        size='md'
      >
        Okay
      </Button>
    </Modal>
  )
})
