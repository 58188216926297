import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { Cell, Pie, PieChart } from 'recharts'
import { errorHandler } from 'utils/errorHandler'

const cy = 120
const cx = cy

const iR = cy - 30
const oR = cy

export default function Diversification() {
  const { data: analytics } = useQuery<DashboardAnalytics, Error>(
    'dashboard-analytics',
    async () => {
      try {
        const res = await privateRequest.get('user/dashboard/statistics')
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const data = [
    { name: 'Stocks', value: analytics?.stock ?? 0, color: '#83bdef' },
    { name: 'Forex', value: analytics?.forex ?? 0, color: '#5eca1f' },
    { name: 'Hard Assets', value: analytics?.hardasset ?? 0, color: '#f35858' },
    { name: 'Crypto', value: analytics?.crypto ?? 0, color: '#f6d61c' },
  ]

  const total = data.reduce((acc, item) => acc + item.value, 0)

  return (
    <div className='card'>
      <CardTitle title='Diversification' />
      <div className='flex flex-col items-center justify-center mt-16'>
        <PieChart width={cy * 2 + 10} height={cx + 10}>
          <Pie
            dataKey='value'
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill='#8884d8'
            stroke='none'
            style={{ outline: 'none' }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className='-translate-y-7 text-center'>
          <p className='text-sm text-secondary font-medium'>Invested Amount</p>
          <h4 className='text-lg font-medium'>{total && total?.toLocaleString()} USDT</h4>
        </div>

        <div className='flex flex-wrap justify-between gap-5 mt-20'>
          {data.map((item, index) => (
            <div key={index} className='flex items-center'>
              <div className='w-4 h-4 rounded-full' style={{ backgroundColor: item.color }}></div>
              <p className='ml-2 text-sm text-white font-medium'>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
