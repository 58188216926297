import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'
import changeEmailConfirmationModal from './changeEmailConfirmation.modal'

type Form = {
  email: string
  confirmEmail: string
}

export default NiceModal.create(() => {
  const modal = useModal()
  const changeEmailConfirmationForm = useModal(changeEmailConfirmationModal)

  const [form, setForm] = useState<Form>({
    email: '',
    confirmEmail: '',
  })

  const [errors, setErrors] = useState<Form>({
    email: '',
    confirmEmail: '',
  })

  const requestToChangeEmail = useMutation<{ message: string; traceId: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/profile/changeEmail/request', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        modal.remove()
        changeEmailConfirmationForm.show({ traceId: data.traceId })
      },
    },
  )

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!form.email || !form.confirmEmail) {
      setErrors((prev) => ({
        ...prev,
        email: form.email ? '' : 'Please enter your email',
        confirmEmail: form.confirmEmail ? '' : 'Please confirm your new email',
      }))
      return
    }

    if (form.email !== form.confirmEmail) {
      setErrors((prev) => ({ ...prev, confirmEmail: 'Email does not match' }))
      return
    }

    toast.promise(requestToChangeEmail.mutateAsync(form), {
      loading: 'Requesting to change email...',
      success: (r) => r.message ?? 'Email changed request sent successfully, verify now!',
      error: (err) => err.message ?? 'Requesting failed!',
    })
  }

  return (
    <Modal
      title='Change Email'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-md'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-10'>
        <Input
          label='Email'
          name='email'
          value={form.email}
          onChange={changeHandler}
          error={!!errors.email}
          helpText={errors.email}
        />
        <Input
          label='Confirm Email'
          name='confirmEmail'
          value={form.confirmEmail}
          onChange={changeHandler}
          error={!!errors.confirmEmail}
          helpText={errors.confirmEmail}
        />
        <Button disabled={requestToChangeEmail.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
