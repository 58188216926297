type Props = React.SVGProps<SVGSVGElement>
export default function PortfolioIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_401_2577)'>
        <path
          d='M10 0.078125C4.52812 0.078125 0.078125 4.52812 0.078125 10C0.078125 15.4719 4.52812 19.9219 10 19.9219C15.4719 19.9219 19.9219 15.4719 19.9219 10C19.9219 4.52812 15.4719 0.078125 10 0.078125ZM17.0062 9.5875C16.9125 7.9625 16.2625 6.48438 15.2437 5.34063L16.7125 3.87188C18.1488 5.44006 18.9883 7.46313 19.0844 9.5875H17.0062ZM3.8 10C3.8 6.58125 6.58125 3.8 10 3.8C13.4187 3.8 16.2 6.58125 16.2 10C16.2 13.4187 13.4187 16.2 10 16.2C6.58125 16.2 3.8 13.4187 3.8 10ZM14.6625 4.75313C13.4859 3.70391 11.9894 3.08285 10.4156 2.99062V0.915625C12.6125 1.01563 14.6094 1.89375 16.1312 3.2875L14.6625 4.75313ZM9.5875 0.915625V2.99375C5.90313 3.20937 2.97188 6.26563 2.97188 10C2.97188 11.7906 3.65 13.4187 4.75313 14.6625L3.28438 16.1312C1.80938 14.5125 0.90625 12.3594 0.90625 10C0.90625 5.125 4.7625 1.13125 9.5875 0.915625ZM10 19.0937C7.64062 19.0937 5.49063 18.1906 3.87188 16.7125L5.34063 15.2437C6.58125 16.35 8.2125 17.025 10.0031 17.025C13.7375 17.025 16.7938 14.0938 17.0094 10.4094H19.0875C18.8688 15.2375 14.875 19.0937 10 19.0937Z'
          fill='currentColor'
        />
        <path
          d='M11.2687 12.5094V6.66562C11.2687 6.4375 11.0844 6.25312 10.8562 6.25312H9.14686C8.91874 6.25312 8.73436 6.4375 8.73436 6.66562V12.5094C8.73436 12.7375 8.91874 12.9219 9.14686 12.9219H10.8562C11.0844 12.9219 11.2687 12.7375 11.2687 12.5094ZM10.4406 12.0937H9.55936V7.07812H10.4406V12.0937ZM12.4812 12.9219H14.1906C14.4187 12.9219 14.6031 12.7375 14.6031 12.5094V8.31875C14.6031 8.09062 14.4187 7.90625 14.1906 7.90625H12.4812C12.2531 7.90625 12.0687 8.09062 12.0687 8.31875V12.5094C12.0656 12.7375 12.2531 12.9219 12.4812 12.9219ZM12.8937 8.73125H13.775V12.0937H12.8937V8.73125ZM7.54686 9.55937H5.83749C5.60936 9.55937 5.42499 9.74375 5.42499 9.97187V12.5094C5.42499 12.7375 5.60936 12.9219 5.83749 12.9219H7.54686C7.77499 12.9219 7.95936 12.7375 7.95936 12.5094V9.97187C7.95936 9.74375 7.77499 9.55937 7.54686 9.55937ZM7.13436 12.0937H6.24999V10.3844H7.13124V12.0937H7.13436Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_401_2577'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
