import RequireAuth from 'components/auth/RequireAuth'
import { AuthContext } from 'context/AuthContext'
import PageWrapper from 'layout/PageWrapper'
import { useContext, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import PageNotFound from './404'
import AcademyPage from './academy'
import CryptoPage from './crypto'
import Dashboard from './dashboard'
import ForexPage from './forex'
import ForgotPassword from './forgot-password'
import HardAssetPage from './hard-asset'
import InvestPage from './invest'
import LoginPage from './login'
import PortfolioPage from './portfolio'
import RegisterPage from './register'
import RegistrationSuccessful from './registration-successful'
import ResetPassword from './reset-password'
import SettingsPage from './settings'
import TStocksPage from './t-stocks'
import VerifyAccount from './verify'
import VerifyForgotPassword from './verify-forgot-password'
import VerifyOtp from './verify-otp'

const privateRoute: RouteObject[] = [
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      { index: true, element: <Navigate to='dashboard' /> },
      {
        path: 'dashboard',
        element: (
          <PageWrapper title='Dashboard'>
            <Dashboard />
          </PageWrapper>
        ),
      },
      {
        path: 'invest',
        element: (
          <PageWrapper title='Invest'>
            <InvestPage />
          </PageWrapper>
        ),
      },
      {
        path: 'crypto',
        element: (
          <PageWrapper title='Crypto'>
            <CryptoPage />
          </PageWrapper>
        ),
      },
      {
        path: 't-stocks',
        element: (
          <PageWrapper title='T-Stocks'>
            <TStocksPage />
          </PageWrapper>
        ),
      },
      {
        path: 'forex',
        element: (
          <PageWrapper title='Forex'>
            <ForexPage />
          </PageWrapper>
        ),
      },
      {
        path: 'hard-asset',
        element: (
          <PageWrapper title='Hard Asset'>
            <HardAssetPage />
          </PageWrapper>
        ),
      },
      {
        path: 'portfolio',
        element: (
          <PageWrapper title='Portfolio'>
            <PortfolioPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy',
        element: (
          <PageWrapper title='Academy'>
            <AcademyPage />
          </PageWrapper>
        ),
      },
      {
        path: 'settings',
        element: (
          <PageWrapper title='Settings'>
            <SettingsPage />
          </PageWrapper>
        ),
      },
      { path: 'login', element: <Navigate to='/' /> },
      { path: 'register', element: <Navigate to='/' /> },
      { path: 'verify-otp', element: <Navigate to='/' /> },
      { path: 'verify', element: <Navigate to='/' /> },
      { path: 'forgot-password', element: <Navigate to='/' /> },
      { path: 'verify-forgot-password', element: <Navigate to='/' /> },
      { path: 'reset-password', element: <Navigate to='/' /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]

const publicRoute: RouteObject[] = [
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'verify-otp', element: <VerifyOtp /> },
  { path: 'verify', element: <VerifyAccount /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'verify-forgot-password', element: <VerifyForgotPassword /> },
  { path: 'reset-password', element: <ResetPassword /> },
  { path: 'registration-successful', element: <RegistrationSuccessful /> },
  { path: '*', element: <Navigate to='/login' /> },
]

const Routes = () => {
  const { token } = useContext(AuthContext)

  const [routes, setRoutes] = useState<RouteObject[]>(token ? privateRoute : publicRoute)

  useEffect(() => {
    setRoutes(token ? privateRoute : publicRoute)
  }, [token])

  const appRoutes = useRoutes(routes)

  if (token) {
    return <div>{appRoutes}</div>
  }

  return <div>{appRoutes}</div>
}

export default Routes
