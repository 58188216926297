// import { Cell, Pie, PieChart } from 'recharts'

import { useModal } from '@ebay/nice-modal-react'
import AnalyticCard from 'components/AnalyticCard'
import Button from 'components/form/Button'
import { privateRequest } from 'config/axios.config'
// import GradientText from 'components/GradientText'
// import CardTitle from 'components/title/cardTitle'

// import contractDuration from 'images/contract-duration.svg'
import GradientText from 'components/GradientText'
import CardTitle from 'components/title/cardTitle'
import airdropHistoryModalModal from 'features/invest/claim/airdrop-history.modal'
import stocksPortfolio from 'images/t-stocks/stocks-portfolio.svg'
import totalInvested from 'images/t-stocks/total-invested.svg'
import totalShares from 'images/t-stocks/total-shares.svg'
import token from 'images/token.svg'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import investmentListModal from './investmentList.modal'
import withdrawModal from './withdraw.modal'

// const RADIAN = Math.PI / 180
// const data = [
//   { name: 'A', value: 33, color: '#0CAD60' },
//   { name: 'B', value: 33, color: '#FFDC5F' },
//   { name: 'C', value: 34, color: '#ED3131' },
// ]
// const cx = 150
// const cy = 100
// const iR = 50
// const oR = 100
// const value = 50

// const needle = (
//   value: number,
//   data: any[],
//   cx: number,
//   cy: number,
//   iR: number,
//   oR: number,
//   color: string | undefined,
// ) => {
//   let total = 0
//   data.forEach((v: { value: number }) => {
//     total += v.value
//   })
//   const ang = 180.0 * (1 - value / total)
//   const length = (iR + 2 * oR) / 3
//   const sin = Math.sin(-RADIAN * ang)
//   const cos = Math.cos(-RADIAN * ang)
//   const r = 5
//   const x0 = cx + 5
//   const y0 = cy + 5
//   const xba = x0 + r * sin
//   const yba = y0 - r * cos
//   const xbb = x0 - r * sin
//   const ybb = y0 + r * cos
//   const xp = x0 + length * cos
//   const yp = y0 + length * sin

//   return [
//     <circle key={RADIAN} cx={x0} cy={y0} r={r} fill={color} stroke='none' />,
//     <path
//       key={RADIAN + 0.1}
//       d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
//       stroke='#none'
//       fill={color}
//     />,
//   ]
// }

export default function AnalyticsSection() {
  const withdraw = useModal(withdrawModal)
  const investmentList = useModal(investmentListModal)
  const airdropHistory = useModal(airdropHistoryModalModal)

  const { data } = useQuery<PortfolioStatistics>('portfolio-statistics', async () => {
    try {
      const res = await privateRequest.get('user/wallet/portfolio')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    // <div className='grid grid-cols-11 gap-3'>
    <div className='grid gap-3'>
      <div className='col-span-8'>
        <div className='grid sm:grid-cols-2 2xl:grid-cols-4 gap-3'>
          <AnalyticCard
            icon={totalInvested}
            title='Available Balance'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.balance?.toFixed(2)} USDT
              </div>
            }
            buttonEl={
              <Button
                onClick={() => withdraw.show()}
                className='!px-4 rounded-md overflow-hidden'
                size='md'
              >
                Withdraw
              </Button>
            }
          />
          <AnalyticCard
            icon={stocksPortfolio}
            title='Incoming Yield'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.totalYield?.toFixed(2)} USDT
              </div>
            }
          />
          <AnalyticCard
            icon={totalShares}
            title='Total Investment'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.totalInvestment?.toFixed(2)} USDT
              </div>
            }
            buttonEl={
              <div className='flex justify-end flex-wrap gap-2'>
                <Button
                  size='md'
                  onClick={() => investmentList.show()}
                  className='rounded-md !px-4'
                >
                  Investment List
                </Button>
                <Button
                  onClick={() => airdropHistory.show()}
                  size='md'
                  className='rounded-md !px-4'
                >
                  Claim Airdrop
                </Button>
              </div>
            }
          />
          <div className='card overflow-hidden'>
            <CardTitle title='Contract Address' />
            <div className='pl-4 flex items-center flex-nowrap overflow-hidden font-medium text-sm mt-6'>
              <p className='text-ellipsis overflow-hidden whitespace-nowrap'>
                0x07ac2c36f323c318f36d42d771c048ff85687ca5
              </p>{' '}
              <GradientText className='text-sm font-medium ml-3'>
                <a
                  target='_blank'
                  href='https://bscscan.com/address/0x07ac2c36f323c318f36d42d771c048ff85687ca5#code'
                  rel='noreferrer'
                >
                  View
                </a>
              </GradientText>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='col-span-3'>
        <div className='card flex flex-col gap-4'>
          <div>
            <CardTitle title='Contract Duration' className='!mb-0' />
            <h2 className='font-medium text-[26px] text-secondary ml-4'>6 Months</h2>
            <img className='ml-4 max-w-full mt-4' src={contractDuration} alt='' />
          </div>
          <div className='pt-8'>
            <CardTitle title='Risk' />
            <div className='flex flex-col items-center justify-center'>
              <PieChart width={300} height={150}>
                <Pie
                  dataKey='value'
                  startAngle={180}
                  endAngle={0}
                  data={data}
                  cx={cx}
                  cy={cy}
                  innerRadius={iR}
                  outerRadius={oR}
                  fill='#8884d8'
                  stroke='none'
                  style={{ outline: 'none' }}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {needle(value, data, cx, cy, iR, oR, '#d0d000')}
              </PieChart>
              <p className='-translate-y-7'>Medium</p>
            </div>
          </div>
          <div>
            <CardTitle title='Yield Position' />
            <div className='flex flex-col items-center justify-center'>
              <PieChart width={300} height={150}>
                <Pie
                  dataKey='value'
                  startAngle={180}
                  endAngle={0}
                  data={data}
                  cx={cx}
                  cy={cy}
                  innerRadius={iR}
                  outerRadius={oR}
                  fill='#8884d8'
                  stroke='none'
                  style={{ outline: 'none' }}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {needle(20, data, cx, cy, iR, oR, '#d0d000')}
              </PieChart>
              <p className='-translate-y-7'>Low</p>
            </div>
          </div>
          <div>
            <CardTitle title='Contract Address' />
            <p className='font-medium text-sm mt-1'>
              0xfcvf65s4fgv56a454fver4.......4erft4gv5d4v{' '}
              <GradientText className='text-sm font-medium ml-3'>View</GradientText>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  )
}
