import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { SyntheticEvent, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  email: string
}

export default function ForgotPassword() {
  const navigate = useNavigate()
  const [form, setForm] = useState<Form>({
    email: '',
  })

  const requestToForgotPassword = useMutation<
    { data: { traceId: string }; message: string },
    Error,
    Form
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/auth/passwordReset', payload)
        return res.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        navigate('/verify-forgot-password', {
          state: {
            traceId: data.data.traceId,
          },
        })
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!form.email) {
      toast.error('Email is required')
      return
    }

    toast.promise(requestToForgotPassword.mutateAsync(form), {
      loading: 'Requesting...',
      success: (res) => res.message || 'Password reset email sent',
      error: (err) => err?.message || 'Failed to send request',
    })
  }

  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Forgot Password</title>
      </Helmet>
      <AuthLayout title='Forgot Password'>
        <form className='flex flex-col gap-6' onSubmit={onSubmit}>
          <Input
            onChange={changeHandler}
            value={form.email}
            label='Email'
            name='email'
            type='email'
          />

          <Button fullWidth disabled={requestToForgotPassword.isLoading}>
            Verify
          </Button>
        </form>

        <p className='mt-12 text-white text-xs text-center'>
          Already have an account?
          <Link className='text-secondary' to='/login'>
            <GradientText className='font-semibold ml-2' direction='bg-gradient-to-b'>
              Log In
            </GradientText>
          </Link>
        </p>
      </AuthLayout>
    </>
  )
}
