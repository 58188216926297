import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'

type Form = {
  amount: string
  address: string
  coin: Option
  chain?: Option
}

type Payload = {
  amount: string
  coin: string
  address: string
  chain: string
}

const coins: Option[] = [
  { label: 'BTC', value: 'BTC' },
  { label: 'USDT', value: 'USDT' },
  { label: 'BUSD', value: 'BUSD' },
]

const usdtChains: Option[] = [
  { label: 'ERC20', value: 'ERC20' },
  { label: 'BEP20', value: 'BEP20' },
]

export default NiceModal.create(() => {
  const queryClient = useQueryClient()
  const modal = useModal()

  const [form, setForm] = useState<Form>({
    amount: '',
    address: '',
    coin: coins[0],
  })

  const [errors, setErrors] = useState<Payload>({
    amount: '',
    coin: '',
    address: '',
    chain: '',
  })

  const withdraw = useMutation<{ message: string }, Error, Payload>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/wallet/withdraw', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('portfolio-statistics')
        queryClient.invalidateQueries('wallet-transactions')
        modal.remove()
      },
    },
  )

  const changeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'amount' && isNaN(Number(value))) return
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const selectHandler = ({ target: { name, value } }: SelectElement) => {
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !form.address ||
      !form.amount ||
      !form.coin ||
      (form.coin.value === 'USDT' && !form.chain)
    ) {
      setErrors((prev) => ({
        ...prev,
        address: !form.address ? 'Address is required' : '',
        amount: !form.amount ? 'Amount is required' : '',
        coin: !form.coin ? 'Coin is required' : '',
        chain: form.coin.value === 'USDT' && !form.chain ? 'CHain is required' : '',
      }))
      return
    }

    const payload: Payload = {
      amount: form.amount,
      address: form.address,
      coin: form.coin.value,
      chain: form.chain?.value ?? '',
    }

    if (form.coin.value === 'BTC') {
      payload.chain = 'BITCOIN'
    } else if (form.coin.value === 'BUSD') {
      payload.chain = 'BEP20'
    }

    toast.promise(withdraw.mutateAsync(payload), {
      loading: 'Requesting...',
      success: (res) => res.message ?? 'Requesting success!',
      error: (err) => err.message ?? 'Requesting failed!',
    })
  }

  return (
    <Modal
      title='Withdraw Balance'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-md'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-10'>
        <Input
          label='Wallet Address'
          name='address'
          value={form.address}
          onChange={changeHandler}
          error={!!errors.address}
          helpText={errors.address}
        />
        <Input
          label='Amount'
          name='amount'
          value={form.amount}
          onChange={changeHandler}
          error={!!errors.amount}
          helpText={errors.amount}
          afterFix='USDT'
        />
        <Select
          label='Currency'
          name='coin'
          value={form.coin}
          options={coins}
          onChange={selectHandler}
          error={!!errors.coin}
          helpText={errors.coin}
        />
        {form.coin.value === 'USDT' && (
          <Select
            label='Network'
            name='chain'
            placeholder='Select Network'
            value={form.chain}
            options={usdtChains}
            onChange={selectHandler}
            error={!!errors.chain}
            helpText={errors.chain}
          />
        )}
        <Button disabled={withdraw.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
