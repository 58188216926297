import NiceModal, { useModal } from '@ebay/nice-modal-react'

import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import successScreenModal from 'components/modal/successScreen.modal'
import { privateRequest } from 'config/axios.config'

import bitcoinIcon from 'images/bitcoin-wallet.svg'
import ethIcon from 'images/eth-wallet.svg'
import ltcIcon from 'images/ltc-wallet.svg'
import tetherIcon from 'images/tether-wallet.svg'
import usdcIcon from 'images/usdc-wallet.svg'
import { useEffect } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  data: {
    coin: AllowedCurrency
    amount: string
    investmentId: string
  }
}

export default NiceModal.create(({ data }: Props) => {
  const modal = useModal()
  const successScreen = useModal(successScreenModal)
  const queryClient = useQueryClient()

  const checkStatus = useMutation<
    {
      message: string
      paymentStatus: CoinBasePaymentStatus
    },
    Error
  >(
    async () => {
      try {
        const res = await privateRequest.get(`user/investment/paymentStatus/${data?.investmentId}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: (data) => {
        if (data.paymentStatus === 'active') {
          redirectToActive()
        }
      },
    },
  )

  const redirectToActive = () => {
    queryClient.invalidateQueries('airdrop-list')
    modal.remove()
    successScreen.show({ title: 'Your payment has been successfully processed.' })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatus.mutateAsync()
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Modal
      title='Payment is being processed'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <table className='font-medium text-xs mb-5'>
        <tr>
          <td className='pb-3'>Amount</td>
          <td>
            <GradientText direction='bg-gradient-to-b'>{data.amount}</GradientText>
          </td>
        </tr>
        <tr>
          <td className='py-3'>Coin</td>
          <td className='flex item-center gap-2'>
            {data.coin === 'BTC' && <img src={bitcoinIcon} />}
            {data.coin === 'ETH' && <img src={ethIcon} />}
            {data.coin === 'USDT' && <img src={tetherIcon} />}
            {data.coin === 'LTC' && <img src={ltcIcon} />}
            {data.coin === 'USDC' && <img src={usdcIcon} />}
            {data.coin}
          </td>
        </tr>
      </table>
      <p className='text-xs leading-5 text-secondary'>
        {`Your payment is currently being processed and is pending network confirmation. There's no
        need for you to stay on this page; feel free to navigate elsewhere or even close the tab.
        Rest assured, we'll keep track of the transaction for you. As soon as everything is
        confirmed, we'll promptly send you an email notification to let you know.`}
      </p>
      <Button className='mt-7' fullWidth>
        <LoaderIcon /> Your payment is processing
      </Button>
    </Modal>
  )
})
