import { publicRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useConversionRate = () =>
  useQuery<ConversionRate, Error>('conversionRate', async () => {
    try {
      // const response = await axios.get(
      //   'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Ctether&vs_currencies=usd',
      // )
      const res = await publicRequest.get('utils/currencyRate')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })
