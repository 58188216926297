import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  direction?: 'bg-gradient-to-r' | 'bg-gradient-to-l' | 'bg-gradient-to-t' | 'bg-gradient-to-b'
  className?: string
}

export default function GradientText({
  children,
  direction = 'bg-gradient-to-r',
  className = '',
}: PropsWithChildren<Props>) {
  const classNames = `text-transparent bg-clip-text ${direction} from-primary to-light`
  return <span className={clsx(classNames, className)}>{children}</span>
}
