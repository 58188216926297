import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useHardAssetStatistics = () =>
  useQuery<HardAssetStatistics, Error>('hardAsset-statistics', async () => {
    try {
      const res = await privateRequest.get('user/hardAsset/statistics')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })
