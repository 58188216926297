import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'

import AnalyticCard from 'components/AnalyticCard'
import CardTitle from 'components/title/cardTitle'

import { useCryptoStatistics } from 'hook/useCryptoStatistics'
import bitcoinFund from 'images/bitcoin-fund.svg'
import earlyTokenSale from 'images/early-token-sale.svg'
import liquidityShares from 'images/liquidity-shares.svg'
import token from 'images/token.svg'
import CryptoLineChart from './LineChart'

export default function AnalyticsSection() {
  const { data } = useCryptoStatistics()

  return (
    <div className='flex flex-col lg:grid grid-cols-11 gap-3'>
      <div className='col-span-8'>
        <div className='grid sm:grid-cols-2 2xl:grid-cols-3 gap-3'>
          <AnalyticCard
            icon={bitcoinFund}
            title='Bitcoin Fund'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.bitcoinFund?.toLocaleString() ?? ''}
              </div>
            }
          />
          <AnalyticCard
            icon={earlyTokenSale}
            title='Early token stage'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.earlyTokenStage?.toLocaleString() ?? ''}
              </div>
            }
          />
          <AnalyticCard
            icon={liquidityShares}
            title='Liquidity Shares'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.liquidityShare?.toLocaleString() ?? ''}
              </div>
            }
          />
        </div>
        <CryptoLineChart />
      </div>
      <div className='col-span-3'>
        <div className='card h-full flex flex-col gap-[50px]'>
          <div>
            <CardTitle title='Total Value' />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.totalValue?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
          <div>
            <CardTitle
              title={
                <>
                  Total Yield (Daily)
                  <span className='text-success flex items-center gap-1'>
                    <BiTrendingUp />
                    {data?.dailyYield.dailyYieldGainPercentage?.toFixed(2)} %
                  </span>
                </>
              }
            />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.dailyYield.totalDailyYield?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
          <div>
            <CardTitle
              title={
                <>
                  Total Losses (Daily){' '}
                  <span className='text-danger flex items-center gap-1'>
                    <BiTrendingDown />
                    {data?.dailyLoss.dailyLossGainPercentage?.toFixed(2)?.toLocaleString() ?? ''}%
                  </span>
                </>
              }
            />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.dailyLoss.totalDailyLoss?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}
