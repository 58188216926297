import { Loader } from 'components/Loader'
import { AuthContext } from 'context/AuthContext'
import Layout from 'layout/Layout'
import { getProfile } from 'queries/auth'
import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import UnAuthorized from './UnAuthorized'

const RequireAuth = () => {
  const { setUser, user } = useContext(AuthContext)

  const { isLoading } = getProfile({
    onSuccess: (data) => {
      setUser(data)
    },
  })

  if (isLoading) {
    return <Loader className='h-screen' />
  }

  if (!user) {
    return <UnAuthorized />
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default RequireAuth
