import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'

import AnalyticCard from 'components/AnalyticCard'
import CardTitle from 'components/title/cardTitle'

import { useForexStatistics } from 'hook/useForexStatistics'
import currencyPairs from 'images/forex/currency-pairs.svg'
import totalInvested from 'images/t-stocks/total-invested.svg'
import totalShares from 'images/t-stocks/total-shares.svg'
import token from 'images/token.svg'
import ForexLineChart from './LineChart'

export default function AnalyticsSection() {
  const { data } = useForexStatistics()
  return (
    <div className='flex flex-col lg:grid grid-cols-11 gap-3'>
      <div className='col-span-8'>
        <div className='grid sm:grid-cols-2 2xl:grid-cols-3 gap-3'>
          <AnalyticCard
            icon={totalInvested}
            title='Total Invested'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.totalInvested?.toLocaleString() ?? ''}
              </div>
            }
          />
          <AnalyticCard
            icon={currencyPairs}
            title='Total Currency Pairs'
            value={
              <div className='flex items-center gap-1'>
                <img src={token} alt='' />
                {data?.totalCurrencyPair?.toLocaleString() ?? ''}
              </div>
            }
          />
          <AnalyticCard
            icon={totalShares}
            title='Daily PIPS'
            value={data?.dailyPips?.toLocaleString() ?? 0}
          />
        </div>
        <ForexLineChart />
      </div>
      <div className='col-span-3'>
        <div className='card flex flex-col gap-[50px]'>
          <div>
            <CardTitle title='Total Value' />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.totalValue?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
          <div>
            <CardTitle
              title={
                <>
                  Total Yield (Daily)
                  <span
                    className={` flex items-center gap-1 text-success       
                    `}
                  >
                    <BiTrendingUp />
                    {data?.dailyYield.dailyYieldGainPercentage?.toFixed(2)}%
                  </span>
                </>
              }
            />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.dailyYield.totalDailyYield?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
          <div>
            <CardTitle
              title={
                <>
                  Total Losses (Daily){' '}
                  <span
                    className={`flex items-center gap-1 text-danger
                       
                    `}
                  >
                    <BiTrendingDown />
                    {data?.dailyLoss.dailyLossGainPercentage?.toFixed(2)}%
                  </span>
                </>
              }
            />
            <h2 className='flex items-center gap-1 font-medium text-[26px]'>
              <img src={token} alt='' />
              {data?.dailyLoss.totalDailyLoss?.toLocaleString() ?? ''}
            </h2>
            {/* <p className='font-medium text-sm text-mute mt-1'>~ -- USDT</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}
