import Devider from 'components/Devider'
import { privateRequest } from 'config/axios.config'
import PricingCard from 'features/invest/PricingCard'

import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function InvestPage() {
  const { data, isLoading } = useQuery<Package[], Error>('packages', async () => {
    try {
      const res = await privateRequest.get('user/investment/packages')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const { data: statistics } = useQuery<InvestmentStatistics[], Error>(
    'investment-statistics',
    async () => {
      try {
        const res = await privateRequest.get('user/investment/statistics')
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const minAmounts = data?.map((row) => ({
    type: row.type,
    minAmount: row.minAmount,
  }))

  return (
    <div>
      <div className='grid sm:grid-cols-2 2xl:grid-cols-3 gap-3'>
        {statistics?.map((row, i) => (
          <div className='card' key={i}>
            <h4 className='font-medium text-sm mb-3 capitalize'>{row.package} Yield</h4>
            <h2 className='font-medium text-2xl'>{row.totalInvested?.toFixed(2)} USDT</h2>
          </div>
        ))}
      </div>
      <Devider />
      <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-12'>
        {data?.map((row, i) => (
          <PricingCard minAmounts={minAmounts} key={i} data={row} />
        ))}
        {isLoading &&
          Array(3)
            .fill(0)
            ?.map((_, i) => <PricingCard.skeleton key={i} />)}
      </div>
    </div>
  )
}
