import clsx from 'clsx'
import CardTitle from 'components/title/cardTitle'
import { createRef, useEffect } from 'react'

type Props = {
  className?: string
}

export default function StocksMarketWidget({ className }: Props) {
  const ref: { current: HTMLDivElement | null } = createRef()

  useEffect(() => {
    let refValue: any

    if (ref.current) {
      const script = document.createElement('script')
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js'

      script.async = true
      script.type = 'text/javascript'
      script.innerHTML = JSON.stringify({
        width: '100%',
        height: '100%',
        symbolsGroups: [
          {
            name: 'Stocks',
            symbols: [
              {
                name: 'NASDAQ:TSLA',
              },
              {
                name: 'NASDAQ:AAPL',
              },
              {
                name: 'NASDAQ:NVDA',
              },
              {
                name: 'NASDAQ:AMZN',
              },
              {
                name: 'NASDAQ:AMD',
              },
              {
                name: 'NASDAQ:MSFT',
              },
              {
                name: 'NASDAQ:META',
              },
              {
                name: 'NASDAQ:NFLX',
              },
              {
                name: 'NASDAQ:GOOGL',
              },
              {
                name: 'NYSE:BABA',
              },
              {
                name: 'NYSE:PLTR',
              },
              {
                name: 'NASDAQ:PYPL',
              },
              {
                name: 'NASDAQ:PYPL',
              },
              {
                name: 'NSE:AXISBANK',
              },
              {
                name: 'NASDAQ:MARA',
              },
            ],
          },
        ],
        showSymbolLogo: true,
        colorTheme: 'dark',
        isTransparent: true,
        locale: 'en',
      })

      ref.current.appendChild(script)
      refValue = ref.current
    }

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild)
        }
      }
    }
  }, [ref])

  return (
    <div className={clsx('card')}>
      <CardTitle title='Markets' />
      <div ref={ref} className={clsx(className)} />
    </div>
  )
}
