import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import depositModal from './deposit.modal'

import Skeleton from 'components/Skeleton'
import high from 'images/pricing/high.svg'
import low from 'images/pricing/low.svg'
import medium from 'images/pricing/medium.svg'
import { numberToHumanDuration } from 'utils'

type Props = {
  data: Package
  minAmounts?: {
    type: YieldType
    minAmount: number
  }[]
}

const getNextType = (type: YieldType): YieldType => {
  switch (type) {
    case 'low':
      return 'medium'
    case 'medium':
      return 'high'
    case 'high':
      return 'low'
  }
}

export default function PricingCard({ data, minAmounts }: Props) {
  const depositModalForm = useModal(depositModal)

  const list = [
    { label: 'Yield', value: numberToHumanDuration(data.yield) },
    { label: 'APY', value: `${data.apy}%` },
    { label: 'ROI', value: `${data.roiPercentage}%` },
    {
      label: 'Redemption',
      value: numberToHumanDuration(data.redemption),
    },
    { label: 'Duration', value: numberToHumanDuration(data.duration) },
    { label: 'Currency', value: data.currencies?.reduce((acc, curr) => `${acc}, ${curr}`) },
    { label: 'Contract', value: data.contract },
    { label: 'Network', value: 'ERC20, BEP20' },
  ]

  const maxAmount =
    (minAmounts?.find((row) => row.type === getNextType(data.type))?.minAmount ?? 1) - 1

  return (
    <div className='card'>
      <div className='pt-2 pb-2 2xl:p-10 relative flex items-center justify-between before:absolute before:bottom-0 before:left-0 before:h-[1.35px] before:w-full before:bg-gradient-to-tr before:from-primary before:to-light'>
        <div>
          <h5 className='text-sm lg:text-lg mb-3 lg:mb-5 capitalize'>{data.type} Yield</h5>
          <h2 className='font-semibold text-3xl lg:text-[26px]'>$ {data.minAmount}</h2>
        </div>
        {data.type === 'low' && <img src={low} alt='' />}
        {data.type === 'medium' && <img src={medium} alt='' />}
        {data.type === 'high' && <img src={high} alt='' />}
      </div>
      <div className='pt-2 2xl:p-10 flex flex-col gap-6 xl:gap-8'>
        {list.map((item, index) => (
          <div key={index} className='flex justify-between text-base xl:text-base'>
            <span className='text-secondary'>{item.label}</span>
            <span className='font-semibold'>{item.value}</span>
          </div>
        ))}
        {data.freeAirDrop !== 0 ? (
          <div className='flex justify-between text-base xl:text-base'>
            <span className='text-secondary'>Free Airdrop</span>
            <span className='font-semibold'>${data.freeAirDrop} Tokens</span>
          </div>
        ) : (
          <div className='opacity-0'>.</div>
        )}

        <Button
          onClick={() =>
            depositModalForm.show({
              yield_type: data.type,
              package_id: data._id,
              minAmount: data.minAmount,
              maxAmount,
            })
          }
          size='xl'
          fullWidth
        >
          Deposit
        </Button>
      </div>
    </div>
  )
}

PricingCard.skeleton = () => (
  <div className='card'>
    <div className='pt-2 pb-2 2xl:p-10 relative flex items-center justify-between before:absolute before:bottom-0 before:left-0 before:h-[1.35px] before:w-full before:bg-slate-600'>
      <div>
        <Skeleton className='w-32 h-4 mb-8' />
        <Skeleton className='w-20 h-4' />
      </div>
      <Skeleton className='h-16 w-16 !rounded-full' />
    </div>
    <div className='pt-2 2xl:p-10 flex flex-col gap-6 xl:gap-8'>
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <div key={index} className='flex justify-between text-base xl:text-base'>
            <Skeleton className='w-16 h-4' />
            <Skeleton className='w-20 h-4' />
          </div>
        ))}

      <Skeleton className='w-full h-14' />
    </div>
  </div>
)
