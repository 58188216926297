import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Modal from '.'

type Props = {
  title?: string
  description?: string
  phase?: ButtonColorType
}

export default NiceModal.create(
  ({
    title,
    description = 'You Really want to Confirm this Action ?',
    phase = 'primary',
  }: Props) => {
    // Use a hook to manage the modal state
    const modal = useModal()

    return (
      <Modal
        title={title}
        visible={modal.visible}
        onCancel={() => modal.remove()}
        className='max-w-md'
      >
        <h2>{description}</h2>
        <Modal.Footer>
          <Button
            onClick={() => {
              modal.reject()
              modal.hide()
            }}
            size='md'
            color='default'
          >
            No
          </Button>
          <Button
            onClick={() => {
              modal.resolve()
              modal.hide()
            }}
            size='md'
            color={phase}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  },
)
