import NiceModal, { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'

import NoData from 'components/NoData'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default NiceModal.create(() => {
  const modal = useModal()
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    InvestmentListResponse,
    Error
  >(
    'investment-list',
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `user/investment/list?page=${pageParam}&limit=20&status=pending,active`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []
  return (
    <Modal
      title='Investment List'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-3xl'
    >
      <div id='scrollableDiv' className='max-h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Invest date</td>
                <td>Investment ID</td>
                <td>Contract Transaction Hash</td>
                <td>Investment Amount</td>
                <td>Yield</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>{row.investmentId} </td>

                  <td>
                    <a
                      target='blank'
                      className='hover:underline text-primary'
                      href={`https://bscscan.com/tx/${row.contractHash}`}
                    >
                      {row?.contractHash?.substring(0, 6) +
                        '...' +
                        row?.contractHash?.substring(row.contractHash.length - 4)}
                    </a>
                  </td>

                  <td>{row.amount?.toFixed(2)} USDT</td>
                  <td className='capitalize'>{row.package.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
        {dataList.length === 0 && !isLoading && <NoData />}
      </div>
    </Modal>
  )
})
