import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const getProfile = (params?: { onSuccess?: (data: User) => void }) =>
  useQuery<User, Error>(
    'get-profile',
    async () => {
      try {
        const res = await privateRequest.get('user/profile')
        return res.data.data
      } catch (err) {
        return errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        params?.onSuccess && params.onSuccess(data)
      },
    },
  )
