import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useTStocksStatistics = () =>
  useQuery<StocksStatistics, Error>('tstock-statistics', async () => {
    try {
      const res = await privateRequest.get('user/tstock/statistics')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })
