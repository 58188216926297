import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import successScreenModal from 'components/modal/successScreen.modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'

type Form = {
  code: string
  traceId: string
}

export default NiceModal.create(({ traceId }: { traceId: string }) => {
  const queryClient = useQueryClient()
  const modal = useModal()
  const successScreen = useModal(successScreenModal)

  const [form, setForm] = useState<Form>({
    code: '',
    traceId,
  })

  const [errors, setErrors] = useState<Form>({
    code: '',
    traceId: '',
  })

  const verifyToSetEmailAs2Fa = useMutation<{ message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/profile/twoFa/verify', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        successScreen.show({ title: '2FA Disabled successfully' })
        modal.remove()
      },
    },
  )

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!form.code) return

    toast.promise(verifyToSetEmailAs2Fa.mutateAsync(form), {
      loading: 'Verifying...',
      success: (r) => r.message ?? '2FA Disabled successfully!',
      error: (err) => err.message ?? 'Something went wrong!',
    })
  }

  return (
    <Modal
      title='Unset 2FA'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-md'
    >
      <p>Verify to remove/unset your 2FA authentication</p>
      <form onSubmit={submitHandler} className='flex flex-col gap-10 mt-10'>
        <Input
          label='Enter 6 Digit OTP'
          name='code'
          value={form.code}
          onChange={changeHandler}
          error={!!errors.code}
          helpText={errors.code}
        />
        <Button disabled={verifyToSetEmailAs2Fa.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
