import Devider from 'components/Devider'
import AnalyticsSection from 'features/portfolio/AnalyticsSection'
import TransactionHistory from 'features/portfolio/TransactionHistory'

export default function PortfolioPage() {
  return (
    <>
      <AnalyticsSection />
      <Devider />
      <TransactionHistory />
      {/* <div className='grid grid-cols-11 gap-3'>
        <div className='col-span-8'>
          <TransactionHistory />
        </div>
        <div className='col-span-3'>
          <Sentiment title='What is your sentiment on Investing?' />
        </div>
      </div> */}
    </>
  )
}
