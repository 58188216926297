import Devider from 'components/Devider'
import Sentiment from 'components/SentimentAAPL'
import CryptoMarketWidget from 'components/tradingViewWidgets/CryptoMarketWidget'
import AnalyticsSection from 'features/crypto/AnalyticsSection'
import { useCryptoStatistics } from 'hook/useCryptoStatistics'

export default function CryptoPage() {
  const { data } = useCryptoStatistics()
  const bearish = data?.poll?.result?.bearish ?? 0
  const bullish = data?.poll?.result?.bullish ?? 0
  return (
    <>
      <AnalyticsSection />
      <Devider />
      <div className='xl:grid grid-cols-11 gap-3'>
        <div className='col-span-8 mb-3 xl:mb-0'>
          <CryptoMarketWidget className='h-[400px]' />
        </div>
        <div className='col-span-3'>
          <Sentiment
            type='crypto'
            title='What is your sentiment on Bitcoin?'
            bearish={bearish}
            bullish={bullish}
          />
        </div>
      </div>
    </>
  )
}
