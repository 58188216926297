import clsx from 'clsx'
import CardTitle from 'components/title/cardTitle'
import { createRef, useEffect } from 'react'

type Props = {
  className?: string
}

export default function CryptoMarketWidget({ className }: Props) {
  const ref: { current: HTMLDivElement | null } = createRef()

  useEffect(() => {
    let refValue: any

    if (ref.current) {
      const script = document.createElement('script')
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js'

      script.async = true
      script.type = 'text/javascript'
      script.innerHTML = JSON.stringify({
        width: '100%',
        height: '100%',
        defaultColumn: 'overview',
        screener_type: 'crypto_mkt',
        displayCurrency: 'USD',
        colorTheme: 'dark',
        locale: 'en',
      })

      ref.current.appendChild(script)
      refValue = ref.current
    }

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild)
        }
      }
    }
  }, [ref])

  return (
    <div className={clsx('card')}>
      <CardTitle title='Markets' />
      <div ref={ref} className={clsx(className)} />
    </div>
  )
}
