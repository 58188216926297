import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'

export default NiceModal.create(({ code }: { code: string }) => {
  const modal = useModal()
  const queryClient = useQueryClient()

  const [newCode, setName] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setName(code)
  }, [code])

  const updateAntiFishingCodeMutation = useMutation<
    { message: string },
    Error,
    { antiPhishingCode: string }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.patch('user/profile/updateAntiPhishingCode', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        modal.hide()
      },
    },
  )

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newCode) {
      setError('Code is required')
      return
    }

    toast.promise(updateAntiFishingCodeMutation.mutateAsync({ antiPhishingCode: newCode }), {
      loading: 'Updating anti fishing code...',
      success: (r) => r.message ?? 'Anti fishing code updated',
      error: (r) => r.message ?? 'Failed to update anti fishing code',
    })
  }

  return (
    <Modal
      title='Anti-Phishing Code'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-md'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-10'>
        <Input
          label='Set your code'
          value={newCode}
          onChange={(e) => {
            setName(e.target.value)
            setError('')
          }}
          placeholder='Enter your code'
          error={!!error}
          helpText={error}
        />
        <Button disabled={updateAntiFishingCodeMutation.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
