import { Link } from 'react-router-dom'

export default function RegistrationSuccessful() {
  return (
    <div>
      <div className='flex flex-col items-center justify-center h-screen'>
        <h2 className='text-3xl font-semibold mb-4'>Please verify your email</h2>
        <p className='mb-4 text-lg'>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          We've sent a verification link to your email. To continue, please check your inbox and
          click on the link to verify your email.
        </p>
        <Link to='/login' className='bg-primary mt-10 text-white px-4 py-2 rounded-lg'>
          Go Back
        </Link>
      </div>
    </div>
  )
}
