import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'

export default NiceModal.create(({ name }: { name: string }) => {
  const modal = useModal()
  const queryClient = useQueryClient()

  const [fullName, setName] = useState(name)

  const updateProfile = useMutation<{ message: string }, Error, { name: string }>(
    async (payload) => {
      try {
        const res = await privateRequest.patch('user/profile/updateProfileInformation', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        modal.hide()
      },
    },
  )

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!fullName) return

    toast.promise(updateProfile.mutateAsync({ name: fullName }), {
      loading: 'Updating profile...',
      success: (r) => r.message ?? 'profile updated',
      error: (r) => r.message ?? 'Failed to update name',
    })
  }

  return (
    <Modal
      title='Change Name'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-md'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-10'>
        <Input
          label='Name'
          value={fullName}
          onChange={(e) => setName(e.target.value)}
          placeholder='Enter your name'
          error={!fullName}
          helpText={!fullName ? 'Name is required' : undefined}
        />
        <Button disabled={updateProfile.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
