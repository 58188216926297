type Props = React.SVGProps<SVGSVGElement>
export default function StockIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9844 4.02344L16.2754 6.31445L11.3945 11.1953L7.41016 7.21094L0.0390625 14.582L1.43359 15.9766L7.41016 10L11.3945 13.9844L17.6699 7.70898L19.9609 10V4.02344H13.9844Z'
        fill='currentColor'
      />
    </svg>
  )
}
