import clsx from 'clsx'
import { createRef, useEffect } from 'react'

type Props = {
  className?: string
}

export default function MarketOverviewWidget({ className }: Props) {
  const ref: { current: HTMLDivElement | null } = createRef()

  useEffect(() => {
    let refValue: any

    if (ref.current) {
      const script = document.createElement('script')
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js'

      script.async = true
      script.type = 'text/javascript'
      script.innerHTML = JSON.stringify({
        colorTheme: 'dark',
        dateRange: '12M',
        showChart: false,
        locale: 'en',
        width: '100%',
        height: '100%',
        largeChartUrl: '',
        isTransparent: true,
        showSymbolLogo: true,
        showFloatingTooltip: false,
        plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
        plotLineColorFalling: 'rgba(41, 98, 255, 1)',
        gridLineColor: 'rgba(240, 243, 250, 0)',
        scaleFontColor: 'rgba(106, 109, 120, 1)',
        belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
        belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
        belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
        belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
        symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
        tabs: [
          {
            title: 'Stocks',
            symbols: [
              {
                s: 'NASDAQ:TSLA',
                d: 'Tesla Inc',
              },
              {
                s: 'NASDAQ:MSFT',
                d: 'Microsoft',
              },
              {
                s: 'NASDAQ:GOOGL',
                d: 'Google',
              },
              {
                s: 'NASDAQ:AAPL',
                d: 'Apple',
              },
              {
                s: 'NASDAQ:META',
                d: 'Meta',
              },
            ],
          },
          {
            title: 'Crypto',
            symbols: [
              {
                s: 'COINBASE:POLSUSD',
              },
              {
                s: 'COINBASE:AVAXUSD',
              },
              {
                s: 'BITSTAMP:BTCUSD',
              },
              {
                s: 'COINBASE:SHIBUSD',
              },
              {
                s: 'BITSTAMP:DAIUSD',
              },
              {
                s: 'COINBASE:UNIUSD',
              },
              {
                s: 'COINBASE:APEUSD',
              },
              {
                s: 'COINBASE:GRTUSD',
              },
              {
                s: 'BITSTAMP:SANDUSD',
              },
              {
                s: 'COINBASE:AAVEUSD',
              },
              {
                s: 'COINBASE:MANAUSD',
              },
              {
                s: 'BINANCE:FTMUSD',
              },
              {
                s: 'BITSTAMP:ENJUSD',
              },
              {
                s: 'BINANCE:BNBUSD',
              },
              {
                s: 'BITSTAMP:DYDXUSD',
              },
              {
                s: 'BITFINEX:FLOKIUST',
              },
              {
                s: 'CRYPTO:BABYDOGEUSD',
              },
              {
                s: 'CRYPTO:GMTUSD',
              },
              {
                s: 'CRYPTO:MBOXUSD',
              },
              {
                s: 'CRYPTO:RACAUSD',
              },
            ],
          },
          {
            title: 'Forex',
            symbols: [
              {
                s: 'FX:EURUSD',
              },
              {
                s: 'FX_IDC:CHFUSD',
              },
              {
                s: 'FX_IDC:JPYUSD',
              },
              {
                s: 'FX_IDC:SGDUSD',
              },
              {
                s: 'FX:GBPUSD',
              },
              {
                s: 'FX:AUDUSD',
              },
              {
                s: 'FX_IDC:CNYUSD',
              },
              {
                s: 'FX_IDC:BRLUSD',
              },
            ],
            originalTitle: 'Forex',
          },
        ],
      })

      ref.current.appendChild(script)
      refValue = ref.current
    }

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild)
        }
      }
    }
  }, [ref])

  return <div className={clsx(className)} ref={ref} />
}
