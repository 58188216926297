import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import AuthLayout from 'layout/AuthLayout'
import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  code: string
  traceId: string
}

export default function VerifyOtp() {
  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext)
  const traceId = useLocation().state?.traceId
  const via = useLocation().state?.via
  const [form, setForm] = useState<Form>({
    code: '',
    traceId: traceId,
  })

  const verifyOtp = useMutation<{ token: string; message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/auth/verifyLogin', payload)
        return res.data.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('token', data.token)
        setToken(data.token)
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!form.code) {
      toast.error('Code is required')
      return
    }

    if (!form.traceId) {
      navigate('/forgot-password')
      return
    }

    toast.promise(verifyOtp.mutateAsync(form), {
      loading: 'Verifying...',
      success: (res) => res.message || 'Verified successfully',
      error: (err) => err?.message || 'Failed to verify',
    })
  }

  useEffect(() => {
    if (!traceId) {
      navigate('/login')
    }
  }, [traceId, navigate])

  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Verify it’s you</title>
      </Helmet>
      <AuthLayout
        description={
          via === 'email'
            ? 'OTP has been sent to your email'
            : 'OTP has been sent to your google authenticator'
        }
        title='Verify it’s you'
      >
        <form className='mt-10 flex flex-col gap-6' onSubmit={onSubmit}>
          <Input onChange={changeHandler} value={form.code} label='Code' name='code' />

          <Button fullWidth disabled={verifyOtp.isLoading}>
            Verify
          </Button>
        </form>
      </AuthLayout>
    </>
  )
}
