import clsx from 'clsx'
import Tooltip from 'components/Tooltip'
import { ChangeEventHandler } from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'
import HelperText from './HelperText'

type Props = {
  label?: string | JSX.Element
  info?: string
  type?: 'text' | 'password' | 'email' | 'number'
  name?: string
  disabled?: boolean
  className?: string
  inputClassName?: string
  error?: boolean
  helpText?: string
  value?: string | number | readonly string[] | undefined
  placeholder?: string
  min?: number
  max?: number
  multiline?: boolean
  rows?: number
  prefix?: string | JSX.Element
  afterFix?: JSX.Element | string
  readOnly?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  required?: boolean
}

export default function Input({
  label,
  info,
  type = 'text',
  name,
  disabled = false,
  className = '',
  inputClassName = '',
  onChange,
  value = '',
  placeholder,
  error,
  helpText,
  max,
  min,
  prefix = '',
  afterFix,
  readOnly = false,
  multiline = false,
  rows = 5,
  required,
}: Props) {
  console.log('required', required)
  return (
    <div className={clsx('w-full', className)}>
      {label && (
        <label htmlFor={name} className='flex items-center gap-2 text-xs font-normal text-white'>
          {label} {required && <span className='text-red-500'>*</span>}
          {info && (
            <Tooltip message={info}>
              <BsInfoCircleFill color='#666' size={20} className='inline' />
            </Tooltip>
          )}
        </label>
      )}
      <div
        className={clsx(
          'relative flex items-center border-b-[0.5px] border-white placeholder-slate-400 disabled:bg-slate-50 disabled:text-slate-500 focus:outline-none focus:ring-sky-500 w-full sm:text-sm focus:ring-1 invalid:text-pink-600 focus:invalid:ring-pink-500 disabled:shadow-none',
          {
            '!border-red-400': error,
          },
          inputClassName,
        )}
      >
        {prefix && <span className='pl-1'>{prefix}</span>}
        {multiline ? (
          <textarea
            readOnly={readOnly}
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            id={name}
            disabled={disabled}
            className={clsx('pt-4 px-3 h-full w-full outline-none bg-inherit', {
              '!pl-[1px]': prefix,
            })}
          ></textarea>
        ) : (
          <input
            autoComplete='off'
            readOnly={readOnly}
            min={min}
            max={max}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type={type}
            name={name}
            id={name}
            disabled={disabled}
            className={clsx('px-3  h-[46px] w-full outline-none bg-inherit', {
              '!pl-[1px]': prefix,
            })}
          />
        )}
        {afterFix && afterFix}
      </div>
      <HelperText message={helpText} />
    </div>
  )
}
