import { useContext, useState } from 'react'
import { AiFillLock, AiTwotoneSecurityScan } from 'react-icons/ai'
import { FaEnvelope } from 'react-icons/fa'
import { HiPencil } from 'react-icons/hi2'
import styled from 'styled-components'

import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Button from 'components/form/Button'
import AntiFishingIcon from 'components/icons/AntiFishingIcon'
import Google2faIcon from 'components/icons/Google2faIcon'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import changeAntiFishingCodeModal from 'features/settings/changeAntiFishingCode.modal'
import changeEmailModal from 'features/settings/changeEmail.modal'
import changeNameModal from 'features/settings/changeName.modal'
import changePasswordModal from 'features/settings/changePassword.modal'
import setGoogleAuthModal from 'features/settings/setGoogleAuth.modal'
import verifyToSetEmail2faModal from 'features/settings/verifyToSetEmail2fa.modal'
import verifyToUnsetOtpModal from 'features/settings/verifyToUnsetOtp.modal'
import { getProfile } from 'queries/auth'
import { toast } from 'react-hot-toast'
import { BiCheck } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

const GroupTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: #c7d9e4;
  margin-bottom: 31px;
`
const GroupHeading = styled.h3`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 14px;
  white-space: nowrap;
`
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`

export default function SettingsPage() {
  const queryClient = useQueryClient()
  const { logOut } = useContext(AuthContext)
  const changePasswordForm = useModal(changePasswordModal)
  const changeEmailForm = useModal(changeEmailModal)
  const setGoogleAuthForm = useModal(setGoogleAuthModal)
  const changeNameForm = useModal(changeNameModal)
  const changeAntiFishingCodeForm = useModal(changeAntiFishingCodeModal)
  const confirm = useModal(confirmationModal)
  const verifyToSetEmail2fa = useModal(verifyToSetEmail2faModal)
  const verifyToUnsetOtp = useModal(verifyToUnsetOtpModal)

  const [file, setFile] = useState<File | null>(null)

  const { data, isLoading, isRefetching } = getProfile()

  const updateProfilePhoto = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.patch('user/profile/uploadProfilePhoto', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
      },
      onError: () => {
        setFile(null)
      },
    },
  )

  const removeAntiFishingCode = useMutation<{ message: string }, Error>(
    async () => {
      try {
        const res = await privateRequest.patch('user/profile/updateAntiPhishingCode', {
          antiPhishingCode: '',
        })
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
      },
    },
  )

  const { mutateAsync: setGoogleAuthenticator } = useMutation<{ secret: string }, Error>(
    async () => {
      try {
        const res = await privateRequest.post('user/profile/setGoogleAuthenticator')
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const requestToChangeTwoFa = useMutation<
    { message: string; data: { traceId: string } },
    Error,
    UserTwoFa
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/profile/twoFa/request', {
          type: payload,
        })
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data, payload) => {
        if (payload === 'none') {
          verifyToUnsetOtp.show({ traceId: data.data.traceId })
          return
        }
        if (payload === 'email') {
          verifyToSetEmail2fa.show({ traceId: data.data.traceId })
        }
        if (payload === 'authenticator') {
          setGoogleAuthForm.show({ traceId: data.data.traceId })
        }
      },
    },
  )

  const deactivateAccount = useMutation<{ message: string }, Error>(
    async () => {
      try {
        const res = await privateRequest.delete('user/profile/deactivateAccount')
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        logOut()
      },
    },
  )

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setFile(e.target.files[0])
      const formData = new FormData()
      formData.append('photo', e.target.files[0])
      toast.promise(updateProfilePhoto.mutateAsync(formData), {
        loading: 'Uploading...',
        success: (r) => r.message ?? 'Profile photo updated successfully',
        error: (r) => r.message ?? 'Something went wrong',
      })
    }
  }

  return (
    <div>
      <div className='flex items-center gap-12'>
        <div className='h-[130px] w-[130px] rounded-full bg-slate-800 overflow-hidden'>
          {file && (
            <img
              className='h-full w-full object-cover'
              src={URL.createObjectURL(file)}
              alt=''
            />
          )}
          {data?.photo && (
            <img
              className='h-full w-full object-cover'
              src={data?.photo }
              alt=''
            />
          )}
        </div>
        <label>
          <input onChange={handleFileChange} type='file' hidden />
          <div className='cursor-pointer btn btn-lg btn-primary btn-outlined'>Change</div>
        </label>
      </div>
      <h2 className='font-semibold text-[26px] mt-7 flex items-center gap-5'>
        <span
          className={clsx({
            'blur-[2px]': isLoading || isRefetching,
          })}
        >
          {data?.name ?? 'Anonymous'}
        </span>
        <HiPencil
          className='cursor-pointer'
          onClick={() => changeNameForm.show({ name: data?.name ?? '' })}
        />
      </h2>

      <div className='my-10'>
        <GroupTitle>Settings</GroupTitle>
        <div className='flex flex-col gap-[44px]'>
          <Group>
            <GroupHeading>
              <FaEnvelope size={25} /> Change Email
            </GroupHeading>
            <Button onClick={() => changeEmailForm.show()} size='lg' variant='outlined'>
              Change
            </Button>
          </Group>
          <Group>
            <GroupHeading>
              <AiFillLock size={25} /> Change Password
            </GroupHeading>
            <Button onClick={() => changePasswordForm.show()} size='lg' variant='outlined'>
              Change
            </Button>
          </Group>
        </div>
        <GroupTitle className='mt-14'>2 Factor Authentication (2FA)</GroupTitle>
        <div className='flex flex-col gap-[44px]'>
          <Group>
            <GroupHeading>
              <CustomRadio
                isActive={data?.twoFa === 'authenticator'}
                onClick={() =>
                  confirm
                    .show({
                      title: 'Confirmation',
                      description: 'Set Google Authenticator as 2FA ?',
                    })
                    .then(() =>
                      setGoogleAuthenticator().then(() =>
                        toast.promise(requestToChangeTwoFa.mutateAsync('authenticator'), {
                          loading: 'Loading...',
                          success: (r) => r.message ?? 'Authenticator successfully set as 2FA',
                          error: (r) => r.message ?? 'Something went wrong',
                        }),
                      ),
                    )
                }
              />
              <Google2faIcon /> Google Authenticator
            </GroupHeading>
            <div className='flex items-center text-lg gap-2'>
              <div
                className={clsx(
                  'h-[25px] w-[25px] rounded-full bg-[#192C24]  grid place-items-center',
                  {
                    'text-[#22D654]': data?.twoFa === 'authenticator',
                    'text-red-500': data?.twoFa !== 'authenticator',
                  },
                )}
              >
                {data?.twoFa === 'authenticator' ? <BiCheck size={18} /> : <MdClose size={18} />}
              </div>
              {data?.twoFa === 'authenticator' ? 'Enabled' : 'Disabled'}
            </div>
            <div>
              {data?.twoFa === 'authenticator' && (
                <Button
                  onClick={() =>
                    confirm
                      .show({
                        title: 'Disable 2FA',
                        description: 'You are about to disable 2FA ?',
                      })
                      .then(() =>
                        toast.promise(requestToChangeTwoFa.mutateAsync('none'), {
                          loading: 'Loading...',
                          success: (r) => r.message ?? 'Authenticator successfully unset',
                          error: (r) => r.message ?? 'Something went wrong',
                        }),
                      )
                  }
                  size='lg'
                  variant='outlined'
                >
                  Disable
                </Button>
              )}
              {data?.twoFa === 'none' && (
                <Button
                  onClick={() =>
                    confirm
                      .show({
                        title: 'Confirmation',
                        description: 'Set Google Authenticator as 2FA ?',
                      })
                      .then(() =>
                        setGoogleAuthenticator().then(() =>
                          toast.promise(requestToChangeTwoFa.mutateAsync('authenticator'), {
                            loading: 'Loading...',
                            success: (r) => r.message ?? 'Authenticator successfully set as 2FA',
                            error: (r) => r.message ?? 'Something went wrong',
                          }),
                        ),
                      )
                  }
                  size='lg'
                  variant='outlined'
                >
                  Enable
                </Button>
              )}
            </div>
          </Group>
          <Group>
            <GroupHeading>
              <CustomRadio
                isActive={data?.twoFa === 'email'}
                onClick={() =>
                  confirm
                    .show({
                      title: 'Confirmation',
                      description: 'Set Email OTP as 2FA ?',
                    })
                    .then(() =>
                      toast.promise(requestToChangeTwoFa.mutateAsync('email'), {
                        loading: 'Loading...',
                        success: (r) => r.message ?? 'Email successfully set as 2FA',
                        error: (r) => r.message ?? 'Something went wrong',
                      }),
                    )
                }
              />
              <AiTwotoneSecurityScan size={25} /> Email OTP
            </GroupHeading>
            {data?.twoFa === 'email' && (
              <Button
                onClick={() =>
                  confirm
                    .show({
                      title: 'Disable 2FA',
                      description: 'You are about to disable 2FA ?',
                    })
                    .then(() =>
                      toast.promise(requestToChangeTwoFa.mutateAsync('none'), {
                        loading: 'Loading...',
                        success: (r) => r.message ?? 'Authenticator successfully unset',
                        error: (r) => r.message ?? 'Something went wrong',
                      }),
                    )
                }
                size='lg'
                variant='outlined'
              >
                Disable
              </Button>
            )}
            {data?.twoFa === 'none' && (
              <Button
                onClick={() =>
                  confirm
                    .show({
                      title: 'Confirmation',
                      description: 'Set Email OTP as 2FA ?',
                    })
                    .then(() =>
                      toast.promise(requestToChangeTwoFa.mutateAsync('email'), {
                        loading: 'Loading...',
                        success: (r) => r.message ?? 'Email successfully set as 2FA',
                        error: (r) => r.message ?? 'Something went wrong',
                      }),
                    )
                }
                size='lg'
                variant='outlined'
              >
                Enable
              </Button>
            )}
          </Group>
        </div>
        <GroupTitle className='mt-14'>Security</GroupTitle>
        <div className='flex flex-col gap-[44px]'>
          <Group>
            <GroupHeading>
              <AntiFishingIcon /> Anti-Phishing code
            </GroupHeading>
            <div className='flex items-center text-lg gap-2'>
              <div
                className={clsx(
                  'h-[25px] w-[25px] rounded-full bg-[#192C24]  grid place-items-center',
                  {
                    'text-[#22D654]': data?.antiPhishingCode,
                    'text-red-500': !data?.antiPhishingCode,
                  },
                )}
              >
                {data?.antiPhishingCode ? <BiCheck size={18} /> : <MdClose size={18} />}
              </div>

              {data?.antiPhishingCode ? (
                <>
                  {data?.antiPhishingCode?.substring(0, 3)}*****{' '}
                  <span
                    onClick={() =>
                      toast.promise(removeAntiFishingCode.mutateAsync(), {
                        loading: 'Loading...',
                        success: (r) => r.message ?? 'Anti-Phishing code successfully removed',
                        error: (r) => r.message ?? 'Something went wrong',
                      })
                    }
                    className='text-danger font-medium text-sm cursor-pointer'
                  >
                    Remove
                  </span>
                </>
              ) : (
                <p className='text-white font-medium text-sm'>Not Set Yet</p>
              )}
            </div>
            <Button
              onClick={() => changeAntiFishingCodeForm.show({ code: data?.antiPhishingCode || '' })}
              size='lg'
              variant='outlined'
            >
              Change
            </Button>
          </Group>
          {/* <Group>
            <GroupHeading>
              <AntiFishingIcon />
              <div>
                <h3 className='mt-8'>Log In Activities</h3>
                <p className='text-sm font-medium text-secondary mt-2'>Last Login 1 days ago</p>
              </div>
            </GroupHeading>
            <Button size='lg' variant='outlined'>
              View
            </Button>
          </Group> */}
        </div>

        <p
          className='text-danger font-semibold cursor-pointer text-lg mt-16'
          onClick={() =>
            confirm.show({ title: 'Confirmation', description: 'Disable Account ?' }).then(() =>
              toast.promise(deactivateAccount.mutateAsync(), {
                loading: 'Loading...',
                success: (r) => r.message ?? 'Account successfully deactivated',
                error: (r) => r.message ?? 'Something went wrong',
              }),
            )
          }
        >
          Disable Account
        </p>
      </div>
    </div>
  )
}

const CustomRadio = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className={clsx('h-5 w-5 border-white border rounded-full cursor-pointer', {
        'relative before:absolute before:h-3 before:w-3 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:bg-primary before:rounded-full !border-primary':
          isActive,
      })}
    />
  )
}
