import { Loader } from 'components/Loader'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  email: string
  token: string
}

export default function VerifyAccount() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const email = params.get('email')
  const token = params.get('token')

  const verifyMutation = useMutation<{ token: string; message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/auth/verifyRegister', payload)
        return res.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSettled: () => {
        navigate('/login')
      },
    },
  )

  useEffect(() => {
    if (token && email) {
      toast.promise(
        verifyMutation.mutateAsync({
          email,
          token,
        }),
        {
          loading: 'Verifying...',
          success: (res) => res.message || 'Verified successfully',
          error: (err) => err?.message || 'Failed to Verify',
        },
      )
    }
  }, [email])

  if (!email && !token) {
    navigate('/register')
  }

  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Verify it’s you</title>
      </Helmet>
      <div>
        <AuthLayout title='Verifying your account...'>
          <Loader />
        </AuthLayout>
      </div>
    </>
  )
}
