import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { errorHandler } from 'utils/errorHandler'

import baby from 'images/coins/baby.png'
import doge from 'images/coins/doge.png'
import floki from 'images/coins/floki.png'
import pepe from 'images/coins/pepe.png'
import shib from 'images/coins/shib.png'

type Payload = {
  coin: string
  address: string
  airdropId: string
  chain: string
}

const supportedCoins: { icon: string; value: string }[] = [
  {
    icon: shib,
    value: 'SHIB',
  },
  {
    icon: pepe,
    value: 'PEPE',
  },
  {
    icon: doge,
    value: 'DOGE',
  },
  {
    icon: baby,
    value: 'BABYDOGE',
  },
  {
    icon: floki,
    value: 'FLOKI',
  },
]

export default NiceModal.create(({ airdropId }: { airdropId: string }) => {
  const modal = useModal()

  const queryClient = useQueryClient()
  const [address, setAddress] = useState<string>()

  const [selectedCoin, setSelectedCoin] = useState(supportedCoins[0])

  const claimAirdrop = useMutation<{ message: string }, Error, Payload>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/airdrop/claim', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('airdrop-list')
      },
    },
  )

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!address) return toast.error('Address is required')
    if (!selectedCoin) return toast.error('Coin is required')

    const payload: Payload = {
      airdropId,
      coin: selectedCoin.value,
      address,
      chain: 'BEP20',
    }

    toast.promise(claimAirdrop.mutateAsync(payload), {
      loading: 'Claiming airdrop...',
      success: (data) => {
        modal.remove()
        return data.message
      },
      error: (err) => err.message ?? 'An error occurred',
    })
  }

  return (
    <Modal
      title='Claim Airdrop'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form className='flex flex-col gap-6' onSubmit={submitHandler}>
      <div className=''>
          <label className='text-xs font-normal text-white block mb-3'>Select coin</label>
          <div className='text-xs font-normal text-white flex gap-3 flex-wrap items-center'>
            {supportedCoins.map((coin, index) => (
              <Button
                key={index}
                type='button'
                variant={selectedCoin.value === coin.value ? 'contained' : 'outlined'}
                onClick={() => setSelectedCoin(coin)}
                className='flex gap-2 items-center !px-5'
              >
                <img src={coin.icon} alt='' />
                {coin.value}
              </Button>
            ))}
          </div>
        </div>
        <p className='text-sm text-[#eee]'>
        <strong>Note:</strong> Please remember, we only support Binance Smart Chain (BSC - BEP-20). Ensure your wallet address is compatible with the BSC network.
        </p>
        <Input
         className='my-2 px-2'
        
          label='Wallet Address'
          value={address}
          name='address'
          onChange={(e) => setAddress(e.target.value)}
        />
      
        <Button fullWidth>Confirm</Button>
      </form>
    </Modal>
  )
})
