import { createContext, PropsWithChildren, useState } from 'react'

const initState: State = {
  user: {},
} as AuthState

export const AuthContext = createContext<AuthState>(initState as AuthState)

type State = {
  user?: User
  token?: string
}

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [auth, setAuth] = useState<State>({
    user: undefined,
    token: localStorage.getItem('token') ?? '',
  })

  const [isDrawerShow, setDrawerShow] = useState<boolean>(false)

  const setUser = (user: User) => {
    setAuth((prev) => ({ ...prev, user: user }))
  }

  const setToken = (token: string) => {
    setAuth((prev) => ({ ...prev, token }))
  }

  const logOut = async () => {
    // toast.promise(privateRequest.post('user/logout'), {
    //   loading: 'Logging out...',
    //   success: () => {
    //     localStorage.removeItem('token')
    //     window.location.replace(loginRedirectUrl)
    //     return 'Logged out successfully'
    //   },
    //   error: 'Failed to log out',
    // })

    localStorage.setItem('token', '')
    setToken('')
  }

  return (
    <AuthContext.Provider
      value={{ ...auth, setUser, setToken, logOut, isDrawerShow, setDrawerShow }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
