import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { SyntheticEvent, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  password: string
  confirmPassword: string
  resetPasswordToken: string
}

export default function ResetPassword() {
  const navigate = useNavigate()
  const resetPasswordToken = useLocation().state?.resetPasswordToken
  const [form, setForm] = useState<Form>({
    password: '',
    confirmPassword: '',
    resetPasswordToken: resetPasswordToken,
  })

  const [errors, setErrors] = useState<Form>({
    password: '',
    confirmPassword: '',
    resetPasswordToken: '',
  })

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const resetPassword = useMutation<{ message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/auth/passwordReset/submit', payload)
        return res.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        navigate('/login')
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    setErrors({
      ...errors,
      [e.target.name]: '',
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!form.password || !form.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        password: 'Password is required',
        confirmPassword: 'Confirm password is required',
      }))
      return
    }

    if (form.password !== form.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: 'Password and confirm password must be same',
      }))
      return
    }

    if (!form.resetPasswordToken) {
      navigate('/forgot-password')
      return
    }

    toast.promise(resetPassword.mutateAsync(form), {
      loading: 'Loading...',
      success: (res) => res.message || 'Password reset successfully',
      error: (err) => err?.message || 'Something went wrong',
    })
  }

  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Reset Password</title>
      </Helmet>
      <AuthLayout title='Reset Password'>
        <form className='flex flex-col gap-6' onSubmit={onSubmit}>
          <Input
            onChange={changeHandler}
            value={form.password}
            label='Password'
            name='password'
            error={!!errors.password}
            helpText={errors.password}
            type='password'
          />
          <Input
            onChange={changeHandler}
            value={form.confirmPassword}
            label='Confirm Password'
            name='confirmPassword'
            type={isPasswordVisible ? 'text' : 'password'}
            afterFix={
              <InputAfterFixShowHider
                isVisible={isPasswordVisible}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                type='eye'
              />
            }
            error={!!errors.confirmPassword}
            helpText={errors.confirmPassword}
          />

          <Button fullWidth disabled={resetPassword.isLoading}>
            Verify
          </Button>
        </form>

        <p className='mt-12 text-white text-xs text-center'>
          Already have an account?
          <Link className='text-secondary' to='/login'>
            <GradientText className='font-semibold ml-2' direction='bg-gradient-to-b'>
              Log In
            </GradientText>
          </Link>
        </p>
      </AuthLayout>
    </>
  )
}
