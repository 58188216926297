type Props = React.SVGProps<SVGSVGElement>

export default function AntiFishingIcon({ width = 25, height = 25, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_230_1470)'>
        <path
          d='M16.1621 4.25962C17.0128 3.95625 17.627 3.15088 17.627 2.19727C17.627 0.985645 16.6413 0 15.4297 0C14.2181 0 13.2324 0.985645 13.2324 2.19727C13.2324 3.15088 13.8466 3.95625 14.6973 4.25962V7.42188H16.1621V4.25962ZM15.4297 1.46484C15.8338 1.46484 16.1621 1.79316 16.1621 2.19727C16.1621 2.60137 15.8338 2.92969 15.4297 2.92969C15.0256 2.92969 14.6973 2.60137 14.6973 2.19727C14.6973 1.79316 15.0256 1.46484 15.4297 1.46484ZM25 22.0703H6.10365e-05C1.22084e-05 23.686 1.31388 25 2.9297 25H22.0703C23.6861 25 25 23.686 25 22.0703ZM23.4863 7.42188H16.1621V15.4785C16.1621 17.5 14.5215 19.1406 12.5 19.1406C10.4785 19.1406 8.8379 17.5 8.8379 15.4785V10.7763L13.5401 15.4785L12.5 16.5186L10.3027 14.3213V15.4785C10.3027 16.6944 11.2841 17.6758 12.5 17.6758C13.7159 17.6758 14.6973 16.6944 14.6973 15.4785V7.42188H1.51368V20.6055H23.4863V7.42188Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_230_1470'>
          <rect width='25' height='25' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
