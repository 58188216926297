import NiceModal, { useModal } from '@ebay/nice-modal-react'
import copy from 'copy-to-clipboard'
import { LoaderIcon, toast } from 'react-hot-toast'
import { BiCopy } from 'react-icons/bi'
import QrCode from 'react-qr-code'

import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import paymentProcessModal from './paymentProcess.modal'

import successScreenModal from 'components/modal/successScreen.modal'
import { privateRequest } from 'config/axios.config'
import bitcoinIcon from 'images/bitcoin-wallet.svg'
import ethIcon from 'images/eth-wallet.svg'
import ltcIcon from 'images/ltc-wallet.svg'
import tetherIcon from 'images/tether-wallet.svg'
import usdcIcon from 'images/usdc-wallet.svg'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  data: CoinBasePaymentResponse
  coin: AllowedCurrency
}

export default NiceModal.create(({ data, coin }: Props) => {
  const modal = useModal()
  const paymentProcess = useModal(paymentProcessModal)
  const successScreen = useModal(successScreenModal)

  const checkStatus = useMutation<
    {
      message: string
      paymentStatus: CoinBasePaymentStatus
    },
    Error
  >(
    async () => {
      try {
        const res = await privateRequest.get(`user/investment/paymentStatus/${data?.id}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: (data) => {
        if (data.paymentStatus === 'pending') {
          redirectToPending()
        }
        if (data.paymentStatus === 'confirmed') {
          redirectToActive()
        }
      },
    },
  )

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode')
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width + 20
      canvas.height = img.height + 20
      if (ctx) {
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }
      ctx?.drawImage(img, 10, 10)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  let address = ''
  let amount = ''
  if (coin === 'BTC') {
    address = data.addresses.bitcoin
    amount = data.pricing.bitcoin.amount ?? '0'
  } else if (coin === 'USDT') {
    address = data.addresses.tether
    amount = data.pricing.tether.amount ?? '0'
  } else if (coin === 'USDC') {
    address = data.addresses.usdc
    amount = data.pricing.usdc.amount ?? '0'
  } else if (coin === 'ETH') {
    address = data.addresses.ethereum
    amount = data.pricing.ethereum.amount ?? '0'
  } else if (coin === 'LTC') {
    address = data.addresses.litecoin
    amount = data.pricing.litecoin.amount ?? '0'
  }

  const redirectToPending = () => {
    modal.remove()
    paymentProcess.show({
      data: { coin, amount, investmentId: data.id },
    })
  }
  const redirectToActive = () => {
    modal.remove()
    successScreen.show({ title: 'Your payment has been successfully processed.' })
  }

  if (data.status === 'pending') {
    redirectToPending()
  }

  if (data.status === 'confirmed') {
    redirectToActive()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatus.mutateAsync()
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Modal
      title='Deposit'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <div className='flex flex-col items-center gap-6 mb-10'>
        <QrCode id='QRCode' className='!w-48 !h-48 p-2 bg-white' value={address} />
        <h2 className='flex items-center flex-wrap gap-2 text-2xl font-medium'>
          {coin === 'BTC' && <img src={bitcoinIcon} />}
          {coin === 'ETH' && <img src={ethIcon} />}
          {coin === 'USDT' && <img src={tetherIcon} />}
          {coin === 'LTC' && <img src={ltcIcon} />}
          {coin === 'USDC' && <img src={usdcIcon} />}
          <GradientText direction='bg-gradient-to-b'>{amount}</GradientText>
          {coin}
        </h2>
        <p className='text-xs flex flex-wrap items-center gap-4'>
          <GradientText className='break-all'>{address}</GradientText>
          <Button
            size='sm'
            onClick={() => {
              copy(address)
              toast.success('Copied to clipboard')
            }}
            className='rounded-md'
          >
            <BiCopy />
          </Button>
        </p>
        <Button onClick={onImageDownload} size='sm' variant='outlined'>
          Save QR Code
        </Button>
        <Button fullWidth>
          <LoaderIcon />
          Awaiting payment from you
        </Button>
      </div>
    </Modal>
  )
})
