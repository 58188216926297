import { PropsWithChildren } from 'react'

type Props = {
  title: string
  value: string | JSX.Element | number
  icon?: string
  // reportLink: string
  buttonEl?: JSX.Element
}

export default function AnalyticCard({
  icon,
  title,
  value,
  buttonEl,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className='card'>
      <div className='flex justify-between mb-3 gap-10'>
        {icon && <img src={icon} alt='' />}
        {/* <Link to={reportLink} className='flex items-center gap-2 text-[#bce1a7]'>
          <HiOutlineDocumentReport /> Reports
        </Link> */}
        {buttonEl && buttonEl}
      </div>
      <h4 className='font-medium text-xs mb-3 capitalize'>{title}</h4>
      <h2 className='font-medium text-xl'>{value}</h2>
      {children}
    </div>
  )
}
