import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import GradientText from 'components/GradientText'
import RegisterForm from 'features/login/RegistrationForm'
import AuthLayout from 'layout/AuthLayout'

export default function RegisterPage() {
  return (
    <>
      <Helmet>
        <title>OpenCall Capital | Register</title>
      </Helmet>
      <AuthLayout
        title='Create Account'
        description='Please provide valid information to create a account'
      >
        <RegisterForm />
        <p className='mt-12 text-white text-xs text-center'>
          Already have an account?
          <Link className='text-secondary' to='/login'>
            <GradientText className='font-semibold ml-2' direction='bg-gradient-to-b'>
              Log In
            </GradientText>
          </Link>
        </p>
      </AuthLayout>
    </>
  )
}
