import moment from 'moment'

export const dateFormatter = (date: Date) => {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
}

export const isAmountValid = (amount: string): boolean => {
  if (amount.match(/^0\d/g) !== null) return false
  if (amount.match(/^\d*\.?\d*$/g) === null) return false
  return true
}

export function maskEmail(email: string) {
  if (!email) return ''
  const atIndex = email.indexOf('@')
  const maskedEmail = email.substring(0, 3) + '*******' + email.substring(atIndex)
  return maskedEmail
}

export const numberToHumanDuration = (number: number) => {
  if (!number || number === 0) return ''
  if (number === 1) return 'Daily'
  if (number === 7) return 'Weekly'
  if (number === 30) return 'Monthly'
  for (let i = 1; i <= 12; i++) {
    if (number === 30 * i) return `${i} Months`
  }
  if (number === 365) return 'Yearly'
  return `${number} Days`
}

export const tickFormatter = (value: number) => (value > 999 ? `$ ${value / 1000}k` : `$ ${value}`)

export const equivalentAmount = (
  amount: number,
  rate: ConversionRate | undefined,
  currency: AllowedCurrency,
): number | undefined => {
  if (!rate) return amount
  if (currency === 'BTC') return amount * (rate.usdtToCurrency.usd?.btc ?? 0)
  if (currency === 'ETH') return amount * (rate.usdtToCurrency.usd?.eth ?? 0)
  if (currency === 'USDT') return amount * (rate.usdtToCurrency.usd?.usdt ?? 0)
  if (currency === 'LTC') return amount * (rate.usdtToCurrency.usd?.ltc ?? 0)
  if (currency === 'USDC') return amount * (rate.usdtToCurrency.usd?.usdc ?? 0)
  return amount
}
