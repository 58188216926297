import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'

import CardTitle from 'components/title/cardTitle'
import { errorHandler } from 'utils/errorHandler'

import moment from 'moment'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { tickFormatter } from 'utils'

export default function TotalInvestLineChart() {
  const { data } = useQuery<DashboardChartData, Error>('dashboard-chartData', async () => {
    try {
      const res = await privateRequest.get('user/dashboard/chartData')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card pb-5'>
      <CardTitle title='Daily Yield' />
      <div className='h-[400px]'>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            width={500}
            height={300}
            data={data?.chartData
              ?.map((el) => ({
                name: el._id,
                totalValue: el.totalValue,
                totalYield: el.totalYield,
              }))
              .reverse()}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#D2EAFF' vertical={false} strokeWidth={0.5} opacity={0.5} />
            <XAxis
              dataKey='name'
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => moment.utc(value).format('YYYY-MM-DD')}
            />
            <YAxis
              tickCount={8}
              tickFormatter={tickFormatter}
              style={{
                fill: '#fff',
                fontSize: '10px',
                fontWeight: '500',
              }}
              orientation='right'
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1E2A47',
                border: 'none',
                borderRadius: '10px',
                color: '#fff',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '14px',
                padding: '10px',
                textAlign: 'center',
              }}
              formatter={(value: number) => [Number(value?.toFixed(2) || 0), 'Total Yield']}
              labelFormatter={(value) => moment(value).format('MMM DD, YYYY')}
            />
            {/* <Line type='monotone' dataKey='totalValue' stroke='#82ca9d' /> */}
            <Line dataKey='totalYield' stroke='#82ca9d' dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
