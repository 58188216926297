import Devider from 'components/Devider'
import Sentiment from 'components/SentimentAAPL'
import AnalyticsSection from 'features/hard-asset/AnalyticsSection'
import { useHardAssetStatistics } from 'hook/useHardAssetStatistics'

export default function HardAssetPage() {
  const { data } = useHardAssetStatistics()
  const bearish = data?.poll?.result?.bearish ?? 0
  const bullish = data?.poll?.result?.bullish ?? 0
  return (
    <>
      <AnalyticsSection />
      <Devider />
      <div className='xl:grid grid-cols-11 gap-3'>
        {/* <div className='col-span-8'>
          <MarketsData />
        </div> */}
        <div className='col-span-3'>
          <Sentiment
            type='hardAsset'
            title='What is your sentiment on Energy?'
            bearish={bearish}
            bullish={bullish}
          />
        </div>
      </div>
    </>
  )
}
