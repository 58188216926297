import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useGetAirdrop = () =>
  useQuery<Airdrop[], Error>('airdrop-list', async () => {
    try {
      const res = await privateRequest.get('user/airdrop/list?limit=-1')
      return res.data.data.docs
    } catch (error) {
      errorHandler(error)
    }
  })
