import { privateRequest } from 'config/axios.config'
import AnalyticCard from './AnalyticCard'

import cryptoIcon from 'images/analytics/crypto.svg'
import forexIcon from 'images/analytics/forex.svg'
import assetIcon from 'images/analytics/hard-asset.svg'
import portfolioIcon from 'images/analytics/portfolio.svg'
import stocksIcon from 'images/analytics/stocks.svg'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function Analytics() {
  const { data } = useQuery<DashboardAnalytics, Error>('dashboard-analytics', async () => {
    try {
      const res = await privateRequest.get('user/dashboard/statistics')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='grid sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 gap-3'>
      <AnalyticCard
        icon={forexIcon}
        title='Forex'
        value={`${data?.forex?.toLocaleString() ?? ''} USDT`}
      />
      <AnalyticCard
        icon={stocksIcon}
        title='T-Stocks'
        value={`${data?.stock?.toLocaleString() ?? ''} USDT`}
      />
      <AnalyticCard
        icon={assetIcon}
        title='Hard Asset'
        value={`${data?.hardasset?.toLocaleString() ?? ''} USDT`}
      />
      <AnalyticCard
        icon={cryptoIcon}
        title='Crypto'
        value={`${data?.crypto?.toLocaleString() ?? ''} USDT`}
      />
      <AnalyticCard
        icon={portfolioIcon}
        title='Total Portfolio'
        value={`${data?.portfolio?.toLocaleString() ?? ''} USDT`}
      />
    </div>
  )
}
