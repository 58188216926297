import Devider from 'components/Devider'
import Sentiment from 'components/SentimentAAPL'
import StocksMarketWidget from 'components/tradingViewWidgets/StocksMarketWidget'
import AnalyticsSection from 'features/t-stocks/AnalyticsSection'
import { useTStocksStatistics } from 'hook/useTStocksStatistics'

export default function TStocksPage() {
  const { data } = useTStocksStatistics()
  const bearish = data?.poll?.result?.bearish ?? 0
  const bullish = data?.poll?.result?.bullish ?? 0
  return (
    <>
      <AnalyticsSection />
      <Devider />
      <div className='xl:grid grid-cols-11 gap-3'>
        <div className='col-span-8 mb-3 xl:mb-0'>
          <StocksMarketWidget className='h-[400px]' />
        </div>
        <div className='col-span-3'>
          <Sentiment
            type='tstock'
            title='What is your sentiment on AAPL?'
            bearish={bearish}
            bullish={bullish}
          />
        </div>
      </div>
    </>
  )
}
