import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import successScreenModal from 'components/modal/successScreen.modal'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import copy from 'copy-to-clipboard'
import { useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import QRCode from 'react-qr-code'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import Modal from '../../components/modal'

export default NiceModal.create(({ traceId }: { traceId: string }) => {
  const { user } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const modal = useModal()
  const successScreen = useModal(successScreenModal)

  const [code, setCode] = useState<string>('')

  const verifyTwoFa = useMutation<{ message: string }, Error, { traceId: string; code: string }>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/profile/twoFa/verify', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        successScreen.show({ title: 'Google Authenticator enabled successfully' })
        modal.remove()
      },
    },
  )

  const { data } = useQuery<{ secret: string }, Error>('googleAuthenticatorCode', async () => {
    try {
      const res = await privateRequest.post('user/profile/setGoogleAuthenticator')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const code = e.currentTarget.code.value
    if (!code) {
      toast.error('Please enter the code')
      return
    }

    toast.promise(verifyTwoFa.mutateAsync({ traceId: traceId ?? '', code }), {
      loading: 'Verifying...',
      success: (r) => r.message ?? 'Verified',
      error: (r) => r.message ?? 'Failed to verify',
    })
  }

  return (
    <Modal
      title='ChangeEnable Google Authenticator'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <h4 className='font-medium text-sm mb-3'>Active authenticator app</h4>
      <p className='text-xs text-secondary leading-6'>
        You’ll need to install a two-factor authentication application on your smartphone/tablet
        such as <span className='text-primary'>Google Authenticator</span>{' '}
        (Android/Iphone/BlackBerry) <span className='text-primary'>Authenticator</span> (Windows
        Phone)
      </p>

      <div className='bg-white inline-block p-5 mt-10'>
        {data?.secret && (
          <QRCode
            size={256}
            className='h-64 w-64'
            value={`otpauth://totp/${user?.name || user?.username}?secret=${
              data?.secret
            }&issuer=OCC`}
          />
        )}
      </div>

      <h4 className='font-medium text-sm mb-3 mt-10'>Configure the app</h4>
      <p className='text-xs text-secondary leading-6'>
        Open your two-factor authentication application and add your account by scanning the QR
        code.{' '}
        <span
          className='text-primary cursor-pointer'
          onClick={() => {
            copy(data?.secret ?? '')
            toast.success('Copied to clipboard')
          }}
        >
          Copy Key
        </span>
      </p>

      <form onSubmit={handleSubmit} className='flex flex-col gap-10 mt-10'>
        <div className='flex items-end'>
          <Input
            label='Enter the 6 digit code'
            name='code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button>Enable</Button>
        </div>
      </form>
    </Modal>
  )
})
