import clsx from 'clsx'
import { useRef, useState } from 'react'

import { BsChevronDown } from 'react-icons/bs'
import useClickOutside from 'utils/useClickOutside'
// import useClickOutside from "utils/useClickOutside";

type Props = {
  value?: Option
  placeholder?: string
  options: Option[]
  name?: string
  className?: string
  containerClass?: string
  align?: 'left' | 'right'
  error?: boolean
  helpText?: string
  label?: string
  isEmpty?: boolean
  onChange: (target: SelectElement) => void
}

const Select = ({
  value,
  placeholder,
  options,
  onChange,
  name = '',
  className = '',
  containerClass = '',
  align = 'left',
  error,
  helpText,
  isEmpty = false,
  label,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen((prev) => !prev)
  }
  const closeHandler = () => {
    setOpen(false)
  }

  const ref = useRef(null)
  useClickOutside(ref, closeHandler)

  const selectHandler = (value: Option) => {
    onChange({ target: { value, name } })
    closeHandler()
  }

  return (
    <div ref={ref} className={clsx('relative inline-block', containerClass)}>
      {label && (
        <label
          htmlFor={name}
          className='block items-center gap-2 text-xs font-normal text-white mb-1'
        >
          {label}
        </label>
      )}
      <div
        onClick={toggle}
        className={clsx(
          'w-full cursor-pointer select-none text-sm flex items-center border-b border-slate-200 border-solid h-8 px-3',
          error && 'border-red-400 focus:border-red-500 focus:ring-red-500',
          className,
        )}
      >
        <span className={clsx('mr-3 whitespace-nowrap', { 'text-gray': !value })}>
          {value?.label ?? placeholder}
        </span>{' '}
        <BsChevronDown className='ml-auto' />
      </div>
      <ul
        className={clsx(
          'absolute top-full z-20 text-sm min-w-full w-max border border-slate-700 border-solid shadow-sm rounded-md bg-dark transition-all max-h-80 overflow-y-auto',
          {
            'visible opacity-100': open,
            'invisible opacity-0': !open,
          },
          align === 'left' ? 'left-0' : 'right-0',
        )}
      >
        {isEmpty && (
          <li
            role='button'
            className='px-3 py-1 hover:bg-slate-900 cursor-pointer'
            onClick={() => selectHandler({ label: 'Select', value: '' })}
          >
            Select
          </li>
        )}
        {options.map((option, i) => (
          <li
            role='button'
            key={i}
            className='px-3 py-1 hover:bg-slate-900 cursor-pointer'
            onClick={() => selectHandler(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
      <p className='text-red-500 text-sm font-semibold my-1'>{helpText}</p>
    </div>
  )
}

export default Select
