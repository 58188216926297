import Devider from 'components/Devider'
import MarketOverviewWidget from 'components/tradingViewWidgets/MarketOverviewWidget'
import Analytics from 'features/dashboard/Analytics'
import Diversification from 'features/dashboard/Diversification'
import DividendYield from 'features/dashboard/DividendYield'
import TotalInvestLineChart from 'features/dashboard/TotalInvestLineChart'

export default function Dashboard() {
  return (
    <>
      <Analytics />
      <Devider />
      <TotalInvestLineChart />
      <Devider />
      <div className='grid lg:grid-cols-3 gap-3'>
        <MarketOverviewWidget className='card min-h-[350px]' />
        <Diversification />
        <DividendYield />
      </div>
    </>
  )
}
